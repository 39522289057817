import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable, Provider } from '@angular/core'
import { firstValueFrom, Observable } from 'rxjs'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from 'src/app/core/models'
import { ProviderInfo } from 'src/app/obstacle/model/provider-info.model'

@Injectable()
export class DataProviderInfoService {
  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {}

  async loadProviderInfo(): Promise<Observable<ProviderInfo>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${this.config.backend_url}rcs/providers`
    return this.http.get<any>(url.toString(), httpOptions)
  }
}
