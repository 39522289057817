<div
  class="position-absolute bottom-20 end-5 z-1"
  *ngIf="
    isOneWarningLayerVisible() ||
    isOneVehicleDataLayerVisible() ||
    isOneProvider1LayerVisible() ||
    isOneProvider2LayerVisible() ||
    isOneProvider3LayerVisible()
  "
>
  <app-legend>
    <app-legend-category
      *ngIf="isOneWarningLayerVisible()"
      name="Road Hazard"
      [metric]="obstacleMetric"
    ></app-legend-category>
    <ng-container *ngIf="providerInfo$ | async as providers">
      <app-legend-category
        isDemoEnabled
        *ngIf="isOneProvider1LayerVisible()"
        name="{{ providers.provider1 }}"
        [metric]="provider1Metric"
      ></app-legend-category>
      <app-legend-category
        isDemoEnabled
        *ngIf="isOneProvider2LayerVisible()"
        name="{{ providers.provider2 }}"
        [metric]="provider2Metric"
      ></app-legend-category>
      <app-legend-category
        isDemoEnabled
        *ngIf="isOneProvider3LayerVisible()"
        name="{{ providers.provider3 }}"
        [metric]="provider3Metric"
      ></app-legend-category>
      <app-legend-category
        isDemoEnabled
        *ngIf="isOneVehicleDataLayerVisible()"
        name="{{ providers.provider4 }}"
        [metric]="vehicleDataMetric"
      ></app-legend-category>
    </ng-container>
  </app-legend>
</div>
