import { Component } from '@angular/core'
import { AppPaths } from 'src/app/app-routing.module'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { Layers } from 'src/app/shared/layers-config/layers'

@Component({
  selector: 'app-obstacle-provider1-data',
  templateUrl: './obstacle-provider1-data.component.html',
})
export class ObstacleProvider1DataComponent {
  public layerIds = Layers
  public isAccidentsLayerVisible: boolean | undefined
  public isBdvVisible: boolean | undefined
  public isConstructionSiteVisible: boolean | undefined

  constructor(private appState: AppStateService) {
    const layers = this.appState.selectLayers()
    this.isAccidentsLayerVisible = layers?.some((layer) => layer === Layers.BingAccidents.name)
    this.isBdvVisible = layers?.some((layer) => layer === Layers.BingBdv.name)
    this.isConstructionSiteVisible = layers?.some((layer) => layer === Layers.BingConstructionSites.name)
  }

  toggleLayerVisbility(layerId: string) {
    switch (layerId) {
      case Layers.BingAccidents.name:
        this.isAccidentsLayerVisible = !this.isAccidentsLayerVisible
        break
      case Layers.BingBdv.name:
        this.isBdvVisible = !this.isBdvVisible
        break
      case Layers.BingConstructionSites.name:
        this.isConstructionSiteVisible = !this.isConstructionSiteVisible
        break
      default:
        break
    }
    this.updateQueryParameters()
  }

  updateQueryParameters() {
    const layers = this.appState
      .selectLayers()
      .filter(
        (layer) =>
          !(layer == Layers.BingBdv.name) &&
          !(layer == Layers.BingAccidents.name) &&
          !(layer == Layers.BingConstructionSites.name),
      )
    if (this.isAccidentsLayerVisible) {
      layers.push(Layers.BingAccidents.name)
    }
    if (this.isBdvVisible) {
      layers.push(Layers.BingBdv.name)
    }

    if (this.isConstructionSiteVisible) {
      layers.push(Layers.BingConstructionSites.name)
    }
    this.appState.setState(AppPaths.Obstacle, {
      layers: layers?.toString(),
    })
  }
}
