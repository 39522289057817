<div class="position-absolute bottom-7 start-20">
  <app-scale-control> </app-scale-control>
</div>
<div class="position-absolute translate-middle-x bottom-7 start-50 col" [class.d-none]="allowedFilters.length <= 1"
  data-cy="dropdown-warnings-menu">
  <div isDemoEnabled ngbDropdown placement="top" class="d-inline-block">
    <button type="button"
      class="btn btn-outline-primary z-3 shadow-sm rounded-2 bg-dark cursor-pointer border hover-effect border-darkgray text-deactivated"
      id="dropdownBasic1" ngbDropdownToggle data-cy="dropdown-warnings-toggle">
      {{ this.currentFilter.split('.')[1] }}
    </button>
    <div isDemoEnabled ngbDropdownMenu
      class="z-3 shadow-sm rounded-2 bg-dark cursor-pointer border border-darkgray hover-effect text-deactivated"
      aria-labelledby="dropdownBasic1" dropdownWarningsToggle>
      <div *ngFor="let filter of allowedFilters">
        <button ngbDropdownItem
          class="z-3 shadow-sm rounded-2 bg-dark hover cursor-pointer hover-effect text-deactivated"
          (click)="setCurrentFilter(filter)" data-cy="dropdown-warnings-item">
          {{ filter }}
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="position-absolute bottom-7 end-5 z-3 shadow-sm rounded-2 bg-dark cursor-pointer border border-darkgray d-flex gap-2 align-items-center text-deactivated">
  <app-auto-refresh (intervalSet)="setLiveModeRefreshInterval($event)" [isActive]="isInLiveMode"></app-auto-refresh>
</div>
<app-map-navigation>

  <ng-container *hasPermissionAny="[
      Permissions.Warnings_Lv_Write,
      Permissions.Warnings_Hr_Write,
      Permissions.Warnings_Hs_Write,
      Permissions.Warnings_Sw_Write
    ]"> <span class="border-bottom border-darkgray"> </span>
    <span class="rb-ic rb-ic-screw-hexagon-head fs-2xl hover-effect p-2"
      [class.sel-menu-item]="isHexagonWarningCreationVisible" (click)="enableSetHexagonWarningMode()" placement="start"
      ngbTooltip="Create area warnings" tooltipClass="custom-tooltip" [openDelay]="1000">
    </span>
  </ng-container>

  <ng-container *hasPermissionAny="[
      Permissions.Warnings_Bdv_Write,
      Permissions.Warnings_Tl_Write,
      Permissions.Warnings_Va_Write
    ]">
    <span class="border-bottom border-darkgray"> </span> <span class="border-bottom border-darkgray"> </span>
    <span class="rb-ic rb-ic-road fs-2xl hover-effect p-2" [class.sel-menu-item]="isRoadWarningCreationVisible"
      (click)="enableSetRoadWarningMode()" placement="start" ngbTooltip="Create road warnings"
      tooltipClass="custom-tooltip" [openDelay]="1000" data-cy="enableRoadWarningdMode">
    </span>
  </ng-container>
  <ng-container *hasPermissionAny="[
      Permissions.Warnings_Bdv_Write,
      Permissions.Warnings_Tl_Write,
      Permissions.Warnings_Va_Write,
      Permissions.Warnings_Lv_Write,
      Permissions.Warnings_Hr_Write,
      Permissions.Warnings_Hs_Write,
      Permissions.Warnings_Sw_Write
    ]">
    <span class="border-bottom border-darkgray"> </span>
    <span class="rb-ic rb-ic-export fs-2xl hover-effect p-2" [class.sel-menu-item]="isWarningImportExportVisible"
      (click)="enableWarningImportExport()" placement="start" ngbTooltip="Import/Export warnings"
      tooltipClass="custom-tooltip" [openDelay]="1000">
    </span>
  </ng-container>
</app-map-navigation>
<app-simulation-warnings-import-export (closed)="closeImportExport()"
  [isVisible]="isWarningImportExportVisible"></app-simulation-warnings-import-export>
<app-simulation-layer-config></app-simulation-layer-config>
<app-simulation-warnings-details></app-simulation-warnings-details>
<app-simulation-area-warnings-details></app-simulation-area-warnings-details>
<app-simulation-create-warnings-details></app-simulation-create-warnings-details>
<app-simulation-create-area-warnings-details></app-simulation-create-area-warnings-details>
<app-last-backend-call></app-last-backend-call>