import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core'
import { Subject, takeUntil, throttleTime } from 'rxjs'
import { AppPaths } from 'src/app/app-routing.module'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from 'src/app/core/models'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { roundToNearestMinutes } from 'date-fns'

@Component({
  selector: 'app-bwc-history-control',
  templateUrl: './bwc-history-control.component.html',
  styleUrls: ['./bwc-history-control.component.scss'],
  standalone: false,
})
export class BwcHistoryControlComponent implements OnDestroy, OnInit {
  @Output() historyClosed = new EventEmitter<void>()

  public currentTimestamp: Date | undefined
  private cleanUp$ = new Subject<void>()
  public currentTimespan: number | undefined
  public isLoading = false
  public zoom: number | undefined

  constructor(
    private appState: AppStateService,
    @Inject(APP_CONFIG) public readonly config: AppConfigModel,
  ) {
    this.evaluateQueryParameters()

    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe(() => {
      this.evaluateQueryParameters()
    })

    this.updateQueryParameters()

    this.appState.isLoadingChanged$
      .pipe(throttleTime(1000, undefined, { leading: true, trailing: true }))
      .subscribe((isLoading) => {
        this.isLoading = isLoading
      })
  }

  ngOnInit(): void {
    this.currentTimestamp = this.appState.selectTimestamp()
      ? this.appState.selectTimestamp()
      : roundToNearestMinutes(new Date(Date.now()), { nearestTo: 15 })
    this.currentTimespan = this.appState.getState().timespan ? this.appState.getState().timespan : 0
  }

  ngOnDestroy(): void {
    this.currentTimestamp = undefined
    this.currentTimespan = undefined
    this.updateQueryParameters()
    this.cleanUp$.next()
    this.cleanUp$.complete()
  }

  handleReloadClick = () => {
    // Needed because of race condition handleOnTimestampChanged
    setTimeout(() => {
      this.appState.reset({ timestamp: undefined })
      this.updateQueryParameters()
    }, 500)
  }

  handleOnHistoryClosed = () => {
    this.historyClosed.emit()
  }

  handleOnTimestampChanged = (timestamp: Date) => {
    this.currentTimestamp = timestamp
  }

  handleOnTimeSpanChanged(timespan: string) {
    this.currentTimespan = Number(timespan)
    this.updateQueryParameters()
  }

  updateQueryParameters() {
    this.appState.setState(AppPaths.BadWeatherCondition, {
      timestamp: this.currentTimestamp?.toISOString(),
      timespan: this.currentTimespan,
    })
  }

  evaluateQueryParameters() {
    this.zoom = this.appState.getState().zoom
    this.zoom = this.zoom ? Math.floor(this.zoom) : this.zoom
    this.currentTimespan = this.appState.getState().timespan
    this.currentTimestamp = this.appState.selectTimestamp()
      ? this.appState.selectTimestamp()
      : this.currentTimestamp
  }

  isZoomLevelAllowedForHistory() {
    return this.zoom && this.zoom >= this.config.history_min_zoom_level
  }
}
