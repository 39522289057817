import { AppStateService } from 'src/app/core/services/app-state.service'
import { MapService } from 'src/app/core/services/map.service'
import { LayerRawEventsService } from '../layers/layer-raw-events.service'
import { DataRawEventsService } from '../services/data-raw-events.service'
import { HttpClient } from '@angular/common/http'
import { AppConfigModel } from 'src/app/core/models'
import { HazardTypes } from '../enums/hazard-types.enum'
import { Layers } from '../layers-config/layers'
import { APP_CONFIG } from 'src/app/app.config'
import { Router } from '@angular/router'
import { HazardSources } from '../enums/hazard-categories.enum'
import { MapEventsService } from 'src/app/core/services/map-events.service'

export const provider1AccidentLayerProvider = {
  provide: 'provider1AccidentLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.Provider1Accidents.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.VehicleAccident,
        HazardSources.Provider,
      ),
      15,
      undefined,
      ['#ed0007', '#007fff'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const provider1BDVLayerProvider = {
  provide: 'provider1BDVLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.Provider1Bdv.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.BrokendownVehicle,
        HazardSources.Provider,
      ),
      15,
      undefined,
      ['#ffe000', '#007fff'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const provider1ConstructioSiteLayerProvider = {
  provide: 'provider1ConstructionSiteLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.Provider1ConstructionSites.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.ConstructionSite,
        HazardSources.Provider,
      ),
      15,
      undefined,
      ['#34a85d', '#007fff'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const vehicleAccidentLayerProvider = {
  provide: 'vehicleAccidentLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.VehicleDataAccidents.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.VehicleAccident,
        HazardSources.Vehicle,
      ),
      15,
      undefined,
      ['#ed0007', '#fff'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const vehicleBDVLayerProvider = {
  provide: 'vehicleBDVLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.VehicleDataBdv.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.BrokendownVehicle,
        HazardSources.Vehicle,
      ),
      15,
      undefined,
      ['#ffe000', '#fff'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const vehicleHRLayerProvider = {
  provide: 'vehicleHRLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.VehicleDataRain.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.HeavyRain,
        HazardSources.Vehicle,
      ),
      15,
      1,
      ['#ed0007', '#fff'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const vehicleFogLayerProvider = {
  provide: 'vehicleFogLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.VehicleDataFog.name,
      8,
      new DataRawEventsService(http, appState, config, HazardTypes.Fog, HazardSources.Vehicle),
      15,
      0,
      ['#7ebdff', '#fff'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const vehicleSlipperyLayerProvider = {
  provide: 'vehicleSlipperyLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.VehicleDataSlippery.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.SlipperyRoad,
        HazardSources.Vehicle,
      ),
      15,
      undefined,
      ['#ffe000', '#fff'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const provider2AccidentLayerProvider = {
  provide: 'provider2AccidentLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.Provider2Accidents.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.VehicleAccident,
        HazardSources.Provider2,
      ),
      15,
      undefined,
      ['#ed0007', '#088735'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const provider2BDVLayerProvider = {
  provide: 'provider2BDVLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.Provider2Bdv.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.BrokendownVehicle,
        HazardSources.Provider2,
      ),
      15,
      undefined,
      ['#ffe000', '#088735'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const provider2ConstructioSiteLayerProvider = {
  provide: 'provider2ConstructionSiteLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.Provider2ConstructionSites.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.ConstructionSite,
        HazardSources.Provider2,
      ),
      15,
      undefined,
      ['#34a85d', '#088735'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const provider3BDVLayerProvider = {
  provide: 'provider3BDVLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.Provider3Bdv.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.BrokendownVehicle,
        HazardSources.Provider3,
      ),
      15,
      undefined,
      ['#ffe000', '#ff9cf5'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}

export const provider3ConstructioSiteLayerProvider = {
  provide: 'provider3ConstructionSiteLayer',
  useFactory: (
    router: Router,
    mapService: MapService,
    mapEventsService: MapEventsService,
    appState: AppStateService,
    http: HttpClient,
    config: AppConfigModel,
  ): LayerRawEventsService => {
    return new LayerRawEventsService(
      router,
      mapService,
      mapEventsService,
      appState,
      Layers.Provider3ConstructionSites.name,
      8,
      new DataRawEventsService(
        http,
        appState,
        config,
        HazardTypes.ConstructionSite,
        HazardSources.Provider3,
      ),
      15,
      undefined,
      ['#34a85d', '#ff9cf5'],
    )
  },
  deps: [Router, MapService, MapEventsService, AppStateService, HttpClient, APP_CONFIG],
}
