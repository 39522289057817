import { Component } from '@angular/core'
import { MetricModel } from 'src/app/shared/models/metric.model'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import { ObstaclesMetrics, getMetricFromMap } from '../../../obstacle/enums/obstacle-metrics.enum'
import { Subject, takeUntil } from 'rxjs'

@Component({
  selector: 'app-obstacle-layer-legend',
  templateUrl: './obstacle-layer-legend.component.html',
})
export class ObstacleLayerLegendComponent {
  private cleanUp$ = new Subject<void>()
  public metrics: string | undefined
  public isVehicleDataBdvLayerVisible = false
  public isVehicleDataAccidentsLayerVisible = false
  public isProvider1BDVLayerVisible = false
  public isProvider1AccidentLayerVisible = false
  public isProvider1ConstructionSitesLayerVisible = false
  public isProvider2BDVLayerVisible = false
  public isProvider2AccidentLayerVisible = false
  public isProvider2ConstructionSitesLayerVisible = false
  public isProvider3BDVLayerVisible = false
  public isProvider3ConstructionSitesLayerVisible = false
  public isLayerSelectionVisible = false
  public isVAWarningLayerVisible = false
  public isBDVWarningLayerVisible = false

  public obstacleMetric: MetricModel | undefined = undefined
  public vehicleDataMetric: MetricModel | undefined = undefined
  public provider1Metric: MetricModel | undefined = undefined
  public provider2Metric: MetricModel | undefined = undefined
  public provider3Metric: MetricModel | undefined = undefined

  constructor(private appState: AppStateService) {
    this.evaluateQueryParameters()

    this.appState.stateChanged.pipe(takeUntil(this.cleanUp$)).subscribe(() => {
      this.evaluateQueryParameters()
    })
  }
  ngOnDestroy(): void {
    this.cleanUp$.next()
    this.cleanUp$.complete()
  }

  isOneVehicleDataLayerVisible() {
    if (this.isVehicleDataBdvLayerVisible || this.isVehicleDataAccidentsLayerVisible) {
      return true
    } else {
      return false
    }
  }

  isOneProvider1LayerVisible() {
    if (
      this.isProvider1AccidentLayerVisible ||
      this.isProvider1BDVLayerVisible ||
      this.isProvider1ConstructionSitesLayerVisible
    ) {
      return true
    } else {
      return false
    }
  }

  isOneProvider2LayerVisible() {
    if (
      this.isProvider2AccidentLayerVisible ||
      this.isProvider2BDVLayerVisible ||
      this.isProvider2ConstructionSitesLayerVisible
    ) {
      return true
    } else {
      return false
    }
  }

  isOneProvider3LayerVisible() {
    if (
      this.isProvider3BDVLayerVisible ||
      this.isProvider3ConstructionSitesLayerVisible
    ) {
      return true
    } else {
      return false
    }
  }

  isOneWarningLayerVisible() {
    if (this.isVAWarningLayerVisible || this.isBDVWarningLayerVisible) {
      return true
    } else {
      return false
    }
  }

  evaluateQueryParameters() {
    this.appState.getState().layers?.includes(Layers.VehicleDataAccidents.name)
      ? (this.isVehicleDataAccidentsLayerVisible = true)
      : (this.isVehicleDataAccidentsLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.VehicleDataBdv.name)
      ? (this.isVehicleDataBdvLayerVisible = true)
      : (this.isVehicleDataBdvLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.Provider1Accidents.name)
      ? (this.isProvider1AccidentLayerVisible = true)
      : (this.isProvider1AccidentLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.Provider1Bdv.name)
      ? (this.isProvider1BDVLayerVisible = true)
      : (this.isProvider1BDVLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.Provider1ConstructionSites.name)
      ? (this.isProvider1ConstructionSitesLayerVisible = true)
      : (this.isProvider1ConstructionSitesLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.Provider2Accidents.name)
      ? (this.isProvider2AccidentLayerVisible = true)
      : (this.isProvider2AccidentLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.Provider2Bdv.name)
      ? (this.isProvider2BDVLayerVisible = true)
      : (this.isProvider2BDVLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.Provider2ConstructionSites.name)
      ? (this.isProvider2ConstructionSitesLayerVisible = true)
      : (this.isProvider2ConstructionSitesLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.Provider3Bdv.name)
      ? (this.isProvider3BDVLayerVisible = true)
      : (this.isProvider3BDVLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.Provider3ConstructionSites.name)
      ? (this.isProvider3ConstructionSitesLayerVisible = true)
      : (this.isProvider3ConstructionSitesLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.VAWarning.name)
      ? (this.isVAWarningLayerVisible = true)
      : (this.isVAWarningLayerVisible = false)

    this.appState.getState().layers?.includes(Layers.BDVWarning.name)
      ? (this.isBDVWarningLayerVisible = true)
      : (this.isBDVWarningLayerVisible = false)

    this.obstacleMetric = this.isBDVWarningLayerVisible
      ? getMetricFromMap(ObstaclesMetrics.Obstacles_BDV)
      : getMetricFromMap(ObstaclesMetrics.Obstacles_Accidents)
    this.vehicleDataMetric = getMetricFromMap(ObstaclesMetrics.VehicleData_Obstacles)
    this.provider1Metric = getMetricFromMap(ObstaclesMetrics.Obstacles_Provider1)
    this.provider2Metric = getMetricFromMap(ObstaclesMetrics.Obstacles_Provider2)
    this.provider3Metric = getMetricFromMap(ObstaclesMetrics.Obstacles_Provider3)
  }
}
