import { Component } from '@angular/core'
import { AppPaths } from 'src/app/app-routing.module'
import { BwcMetrics, getMetricFromMap } from 'src/app/bad-weather-condition/enums/bwc-metrics.enum'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { Layers } from 'src/app/shared/layers-config/layers'

@Component({
  selector: 'app-bwc-weather-prediction',
  templateUrl: './bwc-weather-prediction.component.html',
  standalone: false,
})
export class BwcWeatherPredictionComponent {
  public layerIds = Layers
  public metrics = BwcMetrics
  public getMetricFromMap = getMetricFromMap
  public selectedWeatherMetric: string = ''
  public isWeatherPredictionLayerVisible: boolean | undefined

  constructor(private appState: AppStateService) {
    const layers = this.appState.getState().layers
    if (layers) {
      this.isWeatherPredictionLayerVisible = layers?.includes(Layers.WeatherPrediction.name)
    }

    const weatherMetrics = this.appState.selectMetrics(Layers.WeatherPrediction.name)
    if (weatherMetrics && weatherMetrics.length > 0) {
      this.selectedWeatherMetric = weatherMetrics[0] as BwcMetrics
    }

    this.updateQueryParameters()
  }

  setMetric(metric: string) {
    this.selectedWeatherMetric = this.selectedWeatherMetric == metric ? '' : metric
    this.isWeatherPredictionLayerVisible = this.selectedWeatherMetric === '' ? false : true
    this.updateQueryParameters()
  }

  updateQueryParameters() {
    const layers = this.appState.selectLayers(Layers.WeatherPrediction.name, false)
    if (this.isWeatherPredictionLayerVisible) {
      layers?.push(Layers.WeatherPrediction.name)
    }

    const metrics = this.appState.selectMetrics(Layers.WeatherPrediction.name, false)
    if (this.selectedWeatherMetric) {
      metrics.push(this.selectedWeatherMetric)
    }

    this.appState.setState(AppPaths.BadWeatherCondition, {
      layers: layers?.toString(),
      metrics: metrics?.toString(),
    })
  }
}
