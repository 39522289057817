import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-details-item',
  templateUrl: './details-item.component.html',
  styleUrls: ['./details-item.component.scss'],
  standalone: false,
})
export class DetailsItemComponent {
  @Input() value: string | undefined | null
  @Input() description: string | undefined = ''
  @Input() unit?: string = ''
}
