import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ProviderInfo } from '../../model/provider-info.model'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-obstacle-layer-config',
  templateUrl: './obstacle-layer-config.component.html',
  standalone: false,
})
export class ObstacleLayerConfigComponent {
  @Output() closed = new EventEmitter<void>()
  @Input() isLayerSelectionVisible: boolean = false
  @Input() providerInfo$!: Observable<ProviderInfo>

  toggleLayerSwitchVisibility() {
    this.isLayerSelectionVisible = !this.isLayerSelectionVisible
  }

  public close() {
    this.isLayerSelectionVisible = false
  }
}
