import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Permissions } from 'src/app/core/enums/permissions.enum'

@Component({
  selector: 'app-bwc-layer-config',
  templateUrl: './bwc-layer-config.component.html',
  styleUrls: ['./bwc-layer-config.component.scss'],
  standalone: false,
})
export class BwcLayerConfigComponent {
  @Output() closed = new EventEmitter<void>()
  @Input() isLayerSelectionVisible: boolean = false

  Permissions = Permissions

  constructor() {}

  toggleLayerSwitchVisibility() {
    this.isLayerSelectionVisible = !this.isLayerSelectionVisible
  }

  public close() {
    this.isLayerSelectionVisible = false
  }
}
