import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Permissions } from 'src/app/core/enums/permissions.enum'

@Component({
  selector: 'app-rc-layer-config',
  templateUrl: './rc-layer-config.component.html',
  styleUrls: ['./rc-layer-config.component.scss'],
  standalone: false,
})
export class RcLayerConfigComponent {
  Permissions = Permissions

  @Output() closed = new EventEmitter<void>()
  @Input() isLayerSelectionVisible: boolean = false

  constructor() {}

  toggleLayerSwitchVisibility() {
    this.isLayerSelectionVisible = !this.isLayerSelectionVisible
  }

  public close() {
    this.isLayerSelectionVisible = false
  }
}
