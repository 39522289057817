<div
  class="position-absolute bottom-20 end-5 z-1"
  *ngIf="
    isOneWarningLayerVisible() ||
    isOneVehicleDataLayerVisible() ||
    isOneProvider1LayerVisible() ||
    isOneProvider2LayerVisible() ||
    isOneProvider3LayerVisible()
  "
>
  <app-legend>
    <app-legend-category
      *ngIf="isOneWarningLayerVisible()"
      name="Road Hazard"
      [metric]="obstacleMetric"
    ></app-legend-category>
    <app-legend-category
      *ngIf="isOneProvider1LayerVisible()"
      name="Provider1"
      [metric]="provider1Metric"
    ></app-legend-category>
    <app-legend-category
      *ngIf="isOneProvider2LayerVisible()"
      name="Provider2"
      [metric]="provider2Metric"
    ></app-legend-category>
    <app-legend-category
      *ngIf="isOneProvider3LayerVisible()"
      name="Provider3"
      [metric]="provider3Metric"
    ></app-legend-category>
    <app-legend-category
      *ngIf="isOneVehicleDataLayerVisible()"
      name="Standard Vehicle"
      [metric]="vehicleDataMetric"
    ></app-legend-category>
  </app-legend>
</div>
