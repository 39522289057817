import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'app-layer-button',
  templateUrl: './layer-button.component.html',
  standalone: false,
})
export class LayerButtonComponent {
  @Output() visibilityToggled = new EventEmitter<void>()
}
