import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { ChangeDetectorRef } from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-auto-refresh',
  templateUrl: './auto-refresh.component.html',
  styleUrls: ['./auto-refresh.component.scss'],
  standalone: false,
})
export class AutoRefreshComponent implements AfterContentChecked, AfterViewInit {
  @Output() intervalSet = new EventEmitter<number>()
  @Input() isActive: boolean = false
  @Input() isDisabled: boolean = false
  public isDropdownVisible = false
  public intervalInSeconds = 0
  public isDisabledTemp = false
  public isActiveTemp = false

  public constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.changeDetector.detectChanges() /*cdRef injected in constructor*/
    }, 0)
  }

  ngAfterContentChecked() {
    this.isDisabledTemp = this.isDisabled
    this.isActiveTemp = this.isActive
    this.changeDetector.detectChanges()
  }

  setInterval(seconds: number) {
    this.isDropdownVisible = false
    this.intervalInSeconds = seconds
    this.intervalSet.emit(seconds)
  }

  toggleDropdownVisibility() {
    this.isDropdownVisible = !this.isDropdownVisible
  }

  closeDropdown() {
    this.isDropdownVisible = false
  }
}
