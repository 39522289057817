<div
  *ngIf="isVisible"
  class="debug-window bg-white bottom-30 position-absolute d-flex flex-column start-2 p-2 rounded-2"
>
  <div (click)="copy($event)">
    West (minLon):
    <input type="text" [value]="this.bounds?.getWest()" [readOnly]="true" />
  </div>
  <div (click)="copy($event)">
    South (minLat):
    <input type="text" [value]="this.bounds?.getSouth()" [readOnly]="true" />
  </div>
  <div (click)="copy($event)">
    East (maxLon):
    <input type="text" [value]="this.bounds?.getEast()" [readOnly]="true" />
  </div>
  <div (click)="copy($event)">
    North (maxLat):
    <input type="text" [value]="this.bounds?.getNorth()" [readOnly]="true" />
  </div>
  <div (click)="copy($event)">
    Center (Lon,Lat):
    <input
      type="text"
      value="{{ this.bounds?.getCenter()?.lng }}, {{ this.bounds?.getCenter()?.lat }}"
      [readOnly]="true"
    />
  </div>
  <div (click)="copy($event)">
    Zoom: <input type="text" [value]="this.zoom" [readOnly]="true" />
  </div>
  <div>
    High Accuary:
    <input type="text" [value]="this.config.highAccuracyPositionEnabled" [readOnly]="true" />
  </div>
  <div>
    No. pos updates:
    <input type="text" [value]="this.numberOfLocationChanges" [readOnly]="true" />
  </div>
  <div (click)="copy($event)">
    Token:
    <input type="text" [value]="this.accessToken" [readOnly]="true" />
  </div>
  <div class="flex">
    <span class="flex-grow-1">Permissions</span>
    <textarea [readonly]="true" rows="4">
    {{ this.userPermissions.toString() }}
    </textarea>
  </div>
  <div class="overflow-scroll d-flex flex-column max-vh-30 border-top mt-3">
    <span *ngFor="let messages of messages">{{ messages }}</span>
  </div>
</div>
