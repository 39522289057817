import { Component, Inject } from '@angular/core'
import { LngLat, MapGeoJSONFeature } from 'maplibre-gl'
import { SimulationMetrics, getMetricFromMap } from '../../../enums/simulation-metrics.enum'
import { AuthService } from '../../../../core/services/auth.service'
import { AppConfigModel } from 'src/app/core/models'
import { APP_CONFIG } from 'src/app/app.config'
import { AreaTestWarningDataModel } from 'src/app/shared/models/test-warning-creation.model'
import { MapService } from 'src/app/core/services/map.service'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { debounceTime, firstValueFrom } from 'rxjs'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AppPaths } from 'src/app/app-routing.module'
import { Layers } from 'src/app/shared/layers-config/layers'
import { DetailsModel } from 'src/app/shared/models/details.model'
import { Feature, Geometry } from 'geojson'
import * as h3 from 'h3-js'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { DataSimulationWarningService } from 'src/app/shared/services/data-simulation-warning.service'
import { LayerSimulationWarningService } from 'src/app/shared/layers/layer-simulation-warning.service'

@Component({
  selector: 'app-simulation-create-area-warnings-details',
  templateUrl: './simulation-create-area-warnings-details.component.html',
})
export class SimulationCreateAreaWarningsDetailsComponent {
  public allowedTargets: any = []
  public featureDetails = new Map<string, DetailsModel>()
  public metrics = SimulationMetrics
  public selectedWarningTypeMetric = this.metrics.Warnings_LowVisibility.toString()
  public getMetricFromMap = getMetricFromMap
  private feature: Feature<Geometry, AreaTestWarningDataModel> | undefined
  public duration: number = 15
  public requestStatus: string = 'btn-hover'
  public statusMessage: string = 'Create warning'
  public lat: number = 0
  public lng: number = 0
  public bearing = ''
  public shake = ''
  public point: any
  public refreshIntervalId: NodeJS.Timeout | undefined
  public disableButton: boolean = true
  public Permissions = Permissions
  public isLVLayerVisible: boolean | undefined
  public isHRLayerVisible: boolean | undefined
  public isHSLayerVisible: boolean | undefined
  public isSWLayerVisible: boolean | undefined
  public Layers = Layers

  constructor(
    private readonly mapEventService: MapEventsService,
    private readonly mapService: MapService,
    private readonly authService: AuthService,
    public appState: AppStateService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private dataService: DataSimulationWarningService,
    private simulationTestLayer: LayerSimulationWarningService,
  ) {
    const backendScopes: any = this.authService.getUserClaims().userBackendScopes

    if (backendScopes) {
      backendScopes
        .toString()
        .split(',')
        .forEach((scope: string) => {
          this.allowedTargets.push({ name: scope, selected: false })
        })
      this.allowedTargets.filter((target: any) => target.selected).length > 0
        ? (this.disableButton = false)
        : (this.disableButton = true)
    }

    this.appState.stateChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.feature) {
        this.evaluateQueryParameters()
      }
    })
    this.evaluateQueryParameters()

    this.featureDetails = new Map<string, DetailsModel>()
    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((feature: MapGeoJSONFeature[]) => {
        this.featureDetails.clear()
        if (feature[0].layer.id === Layers.CreateSimAreaWarnings.name) {
          this.mapService.removeMarker()
          this.appState.setState(AppPaths.Simulation, {
            selectedFeatureId: undefined,
          })
          this.feature = feature[0] as unknown as Feature<Geometry, AreaTestWarningDataModel>
          this.appState.setState(AppPaths.Simulation, {
            selectedFeatureId: feature[0].id?.toString() ?? '',
          })
          let point = h3.cellToLatLng(this.feature?.properties.h3id ?? '')
          this.mapService.setMarker(
            {
              lng: point[1],
              lat: point[0],
            } as LngLat,
            `${this.selectedWarningTypeMetric}-marker`,
          )
          this.updateAreaDetail()
        }
      })
  }

  updateAreaDetail() {
    this.featureDetails?.set('Hexagon', {
      title: 'Hexagon',
      items: [
        {
          description: 'Hex ID',
          value: this.feature?.properties.h3id ?? '',
        },
        {
          description: 'Country Code',
          value: this.feature?.properties.countryCode ?? '',
        },
      ],
    })
  }

  close() {
    this.appState.setState(AppPaths.Simulation, {
      selectedFeatureId: undefined,
    })
    this.mapService.removeMarker()
    this.featureDetails = new Map<string, any>()
    this.appState.setState(AppPaths.Simulation, {
      selectedFeatureId: undefined,
    })
  }

  setMetric(warningTypeMetric: string) {
    this.selectedWarningTypeMetric = warningTypeMetric
    this.mapService.changeMarker(`${this.selectedWarningTypeMetric}-marker`)
  }

  async createWarning() {
    const LatLng = h3.cellToLatLng(this.feature?.properties.h3id ?? '')
    let newWarning: AreaTestWarningDataModel = {
      warningState: getMetricFromMap(this.selectedWarningTypeMetric).name,
      latitude: LatLng[0],
      longitude: LatLng[1],
      ttl: this.duration * 60000,
      isCancelled: false,
      targets: this.allowedTargets
        .filter((target: any) => target.selected)
        .map((target: any) => target.name),
      countryCode: this.feature?.properties.countryCode ?? '',
      h3id: this.feature?.properties.h3id ?? '',
    }
    try {
      const result: any = await firstValueFrom(this.dataService.createAreaTestWarnings(newWarning))
      this.requestStatus = 'btn-noncritical'
      this.statusMessage = 'Creation initialized ...'
      setTimeout(() => {
        this.requestStatus = 'btn-hover'
        this.statusMessage = 'Create warning'
        this.close()
      }, 1000)
      clearInterval(this.refreshIntervalId)
      this.refreshIntervalId = setInterval(() => this.simulationTestLayer.reload(), 2000)

      setTimeout(
        (function (id_local) {
          return function () {
            clearInterval(id_local)
          }
        })(this.refreshIntervalId),
        15000,
      )
    } catch (error) {
      this.shake = 'shake'
      this.requestStatus = 'btn-dangerous'
      this.statusMessage = 'Error'
      setTimeout(() => {
        this.shake = ''
        this.requestStatus = 'btn-hover'
        this.statusMessage = 'Create warning'
      }, 1000)
    }
  }

  ngOnDestroy() {
    this.mapService.removeMarker()
  }

  evaluateQueryParameters() {
    // Set the selected warning type marker based on the layers that are visible
    const layers = this.appState.getState().layers
    this.isLVLayerVisible = layers?.includes(Layers.LVWarning.name)
    this.isHRLayerVisible = layers?.includes(Layers.HRWarning.name)
    this.isHSLayerVisible = layers?.includes(Layers.HSWarning.name)
    this.isSWLayerVisible = layers?.includes(Layers.SWWarning.name)

    if (
      (this.selectedWarningTypeMetric == this.metrics.Warnings_LowVisibility.toString() &&
        !this.isLVLayerVisible) ||
      (this.selectedWarningTypeMetric == this.metrics.Warnings_HeavyRain.toString() &&
        !this.isHRLayerVisible) ||
      (this.selectedWarningTypeMetric == this.metrics.Warnings_HeavySnow.toString() &&
        !this.isHSLayerVisible) ||
      (this.selectedWarningTypeMetric == this.metrics.Warnings_StrongWind.toString() &&
        !this.isSWLayerVisible) ||
      this.selectedWarningTypeMetric == ''
    ) {
      if (this.isLVLayerVisible) {
        this.selectedWarningTypeMetric = this.metrics.Warnings_LowVisibility.toString()
      } else if (this.isHRLayerVisible) {
        this.selectedWarningTypeMetric = this.metrics.Warnings_HeavyRain.toString()
      } else if (this.isHSLayerVisible) {
        this.selectedWarningTypeMetric = this.metrics.Warnings_HeavySnow.toString()
      } else if (this.isSWLayerVisible) {
        this.selectedWarningTypeMetric = this.metrics.Warnings_StrongWind.toString()
      } else {
        this.selectedWarningTypeMetric = ''
      }
      if (this.feature) {
        this.mapService.changeMarker(`${this.selectedWarningTypeMetric}-marker`)
      }
    }
  }

  validateScopes(target: any) {
    target.selected = !target.selected

    this.allowedTargets.filter((target: any) => target.selected).length > 0
      ? (this.disableButton = false)
      : (this.disableButton = true)
  }
}
