import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-layer-category',
  templateUrl: './layer-config-category.component.html',
  standalone: false,
})
export class LayerCategoryComponent {
  @Input() description: string = ''
  @Input() isVisible: boolean | undefined = true
}
