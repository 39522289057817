import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-details-item-horizontal',
  templateUrl: './details-item-horizontal.component.html',
  standalone: false,
})
export class DetailsItemHorizontalComponent {
  @Input() value: string | undefined | null
  @Input() description: string = ''
  @Input() unit?: string = ''
}
