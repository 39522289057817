import { Component, Inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { debounceTime, timeout } from 'rxjs/operators'
import { LngLat, MapGeoJSONFeature } from 'maplibre-gl'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import { DetailsModel } from 'src/app/shared/models/details.model'
import { SimulationMetrics, getMetricFromMap } from '../../../enums/simulation-metrics.enum'
import { AuthService } from '../../../../core/services/auth.service'
import { AppConfigModel } from 'src/app/core/models'
import { APP_CONFIG } from 'src/app/app.config'
import { DataSimulationWarningService } from 'src/app/shared/services/data-simulation-warning.service'
import { TestWarningDataModel } from 'src/app/shared/models/test-warning-creation.model'
import { MapService } from 'src/app/core/services/map.service'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { AppPaths } from 'src/app/app-routing.module'
import { firstValueFrom } from 'rxjs'
import { LayerSimulationWarningService } from 'src/app/shared/layers/layer-simulation-warning.service'
import * as turf from '@turf/turf'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { Directions } from '../../../enums/directions.enum'

@Component({
  selector: 'app-simulation-create-warnings-details',
  templateUrl: './simulation-create-warnings-details.component.html',
})
export class SimulationCreateWarningsDetailsComponent {
  public allowedTargets: any = []
  public featureDetails = new Map<string, DetailsModel>()
  public metrics = SimulationMetrics
  public selectedWarningTypeMetric = this.metrics.Warnings_Accidents.toString()
  public getMetricFromMap = getMetricFromMap
  private feature: MapGeoJSONFeature | undefined
  public duration: number = 15
  public requestStatus: string = 'btn-hover'
  public statusMessage: string = 'Create warning'
  public lat: number = 0
  public lng: number = 0
  public bearing = ''
  public shake = ''
  public point: any
  public refreshIntervalId: NodeJS.Timeout | undefined
  public isAccidentsLayerVisible: boolean | undefined
  public isBdvLayerVisible: boolean | undefined
  public isTLLayerVisible: boolean | undefined
  public isCSLayerVisible: boolean | undefined
  public onDestroy = false
  public Permissions = Permissions
  public disableButton: boolean = true
  public isDirectionAlong: boolean | undefined
  public isDirectionAgainst: boolean | undefined
  public isDirectionBoth: boolean | undefined
  public directions = Directions
  public Layers = Layers

  constructor(
    private readonly mapEventService: MapEventsService,
    private readonly mapService: MapService,
    private readonly authService: AuthService,
    public appState: AppStateService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private dataService: DataSimulationWarningService,
    private simulationTestLayer: LayerSimulationWarningService,
  ) {
    const backendScopes: any = this.authService.getUserClaims().userBackendScopes
    if (backendScopes) {
      backendScopes
        .toString()
        .split(',')
        .forEach((scope: string) => {
          this.allowedTargets.push({ name: scope, selected: false })
        })
      this.allowedTargets.filter((target: any) => target.selected).length > 0
        ? (this.disableButton = false)
        : (this.disableButton = true)
    }
    this.appState.stateChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.feature) {
        this.evaluateQueryParameters()
      }
    })
    this.evaluateQueryParameters()

    this.featureDetails = new Map<string, DetailsModel>()
    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((feature: MapGeoJSONFeature[]) => {
        this.featureDetails.clear()
        if (feature[0].layer.id === Layers.CreateSimRoadWarnings.name) {
          this.mapService.removeMarker()
          this.appState.setState(AppPaths.Simulation, {
            selectedFeatureId: undefined,
          })
          this.feature = feature[0]
          this.appState.setState(AppPaths.Simulation, {
            selectedFeatureId: feature[0].id?.toString(),
          })
          this.feature.properties['bidirectional']
            ? ((this.isDirectionAlong = true), (this.isDirectionAgainst = true))
            : ((this.isDirectionAlong = true), (this.isDirectionAgainst = false))

          this.point = turf.nearestPointOnLine(
            this.feature?.geometry as any,
            turf.point([this.feature?.state['selectedLng'], this.feature?.state['selectedLat']]),
          )
          this.mapService.setMarker(
            {
              lng: this.point.geometry.coordinates[0],
              lat: this.point.geometry.coordinates[1],
            } as LngLat,
            `${this.selectedWarningTypeMetric}-marker`,
          )
          this.updateRoadSegmentDetail()
        }
      })
  }

  updateRoadSegmentDetail() {
    this.featureDetails?.set('RoadSegment', {
      title: 'Road Segment',
      items: [
        {
          description: 'Segment ID',
          value: this.feature?.id?.toString() ?? '',
        },
        {
          description: 'Type',
          value: this.feature?.properties['highway'],
        },
        {
          description: 'Coordinates (lng/lat)',
          value: `${this.feature?.state['selectedLng']}, ${this.feature?.state['selectedLat']}`,
        },

        {
          description: 'Bidirectional',
          value: this.feature?.properties['bidirectional'] ? 'Yes' : 'No',
        },
      ],
    })
  }

  close() {
    this.appState.setState(AppPaths.Simulation, {
      selectedFeatureId: undefined,
    })
    this.feature = undefined
    this.mapService.removeMarker()
    this.featureDetails = new Map<string, any>()
    this.appState.setState(AppPaths.Simulation, {
      selectedFeatureId: undefined,
    })
  }

  setMetric(warningTypeMetric: string) {
    this.selectedWarningTypeMetric = warningTypeMetric
    this.mapService.changeMarker(`${this.selectedWarningTypeMetric}-marker`)
  }

  async createWarning() {
    let newWarning: TestWarningDataModel = {
      segmentId: this.feature?.id?.toString() ?? '',
      countryCode: this.feature?.properties['iso_a2_eh'],
      warningState: getMetricFromMap(this.selectedWarningTypeMetric).name,
      latitude: this.point?.geometry.coordinates[1],
      longitude: this.point?.geometry.coordinates[0],
      ttl: this.duration * 60000,
      isCancelled: false,
      class: this.feature?.properties['highway'],
      targets: this.allowedTargets
        .filter((target: any) => target.selected)
        .map((target: any) => target.name),
      bidirectional: this.feature?.properties['bidirectional'],
      direction:
        this.isDirectionAlong && this.isDirectionAgainst
          ? 'both'
          : this.isDirectionAgainst
            ? 'against'
            : 'along',
    }
    try {
      const result: any = await firstValueFrom(this.dataService.createTestWarnings(newWarning))
      this.requestStatus = 'btn-noncritical'
      this.statusMessage = 'Creation initialized ...'
      setTimeout(() => {
        this.requestStatus = 'btn-hover'
        this.statusMessage = 'Create warning'
        this.close()
      }, 1000)
      clearInterval(this.refreshIntervalId)
      this.refreshIntervalId = setInterval(() => this.simulationTestLayer.reload(), 2000)

      setTimeout(
        (function (id_local) {
          return function () {
            clearInterval(id_local)
          }
        })(this.refreshIntervalId),
        15000,
      )
    } catch (error) {
      this.shake = 'shake'
      this.requestStatus = 'btn-dangerous'
      this.statusMessage = 'Error'
      setTimeout(() => {
        this.shake = ''
        this.requestStatus = 'btn-hover'
        this.statusMessage = 'Create warning'
      }, 1000)
    }
  }

  evaluateQueryParameters() {
    // Set the selected warning type marker based on the layers that are visible
    const layers = this.appState.getState().layers
    this.isAccidentsLayerVisible = layers?.includes(Layers.VAWarning.name)
    this.isBdvLayerVisible = layers?.includes(Layers.BDVWarning.name)
    this.isTLLayerVisible = layers?.includes(Layers.TLWarning.name)
    this.isCSLayerVisible = layers?.includes(Layers.CSWarning.name)

    if (
      (this.selectedWarningTypeMetric == this.metrics.Warnings_Accidents.toString() &&
        !this.isAccidentsLayerVisible) ||
      (this.selectedWarningTypeMetric == this.metrics.Warnings_BrokenDownVehicle.toString() &&
        !this.isBdvLayerVisible) ||
      (this.selectedWarningTypeMetric == this.metrics.Warnings_TractionLoss.toString() &&
        !this.isTLLayerVisible) ||
      (this.selectedWarningTypeMetric == this.metrics.Warnings_ConstructionSite.toString() &&
        !this.isCSLayerVisible) ||
      this.selectedWarningTypeMetric == ''
    ) {
      if (this.isAccidentsLayerVisible) {
        this.selectedWarningTypeMetric = this.metrics.Warnings_Accidents.toString()
      } else if (this.isBdvLayerVisible) {
        this.selectedWarningTypeMetric = this.metrics.Warnings_BrokenDownVehicle.toString()
      } else if (this.isTLLayerVisible) {
        this.selectedWarningTypeMetric = this.metrics.Warnings_TractionLoss.toString()
      } else if (this.isCSLayerVisible) {
        this.selectedWarningTypeMetric = this.metrics.Warnings_ConstructionSite.toString()
      } else {
        this.selectedWarningTypeMetric = ''
      }
      if (this.feature) {
        this.mapService.changeMarker(`${this.selectedWarningTypeMetric}-marker`)
      }
    }
  }

  toggleDirection(direction: Directions) {
    if (direction === Directions.Along) {
      this.isDirectionAlong = !this.isDirectionAlong
    }
    if (direction === Directions.Against) {
      this.isDirectionAgainst = !this.isDirectionAgainst
    }
    !this.isDirectionAgainst && !this.isDirectionAlong
      ? (this.disableButton = true)
      : (this.disableButton = false)
  }

  validateScopes(target: any) {
    target.selected = !target.selected

    this.allowedTargets.filter((target: any) => target.selected).length > 0
      ? (this.disableButton = false)
      : (this.disableButton = true)
  }

  ngOnDestroy() {
    this.mapService.removeMarker()
  }
}
