import { Component } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { debounceTime } from 'rxjs/operators'
import { MapGeoJSONFeature } from 'maplibre-gl'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import { DetailsModel } from 'src/app/shared/models/details.model'
import { format } from 'date-fns'
import { RawDataModel } from 'src/app/shared/models/raw-event.model'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { AppPaths } from 'src/app/app-routing.module'
import { get } from 'lodash'
import { getTimeZoneLabel } from 'src/app/shared/utils/date.utils'

@Component({
  selector: 'app-obstacle-raw-details',
  templateUrl: './obstacle-raw-details.component.html',
})
export class ObstacleRawDetailsComponent {
  public details = new Map<string, DetailsModel>()
  public rawFeature: string | undefined
  public features: MapGeoJSONFeature[] = []
  public zoomLevel: number | undefined
  public index: number = 0
  public globalId = ''

  constructor(
    private readonly mapEventService: MapEventsService,
    private appState: AppStateService,
  ) {
    this.details = new Map<string, DetailsModel>()
    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((features: MapGeoJSONFeature[]) => {
        this.details.clear()
        this.features.length != features.length ? (this.index = 0) : this.index
        if (
          features[this.index].layer.id === Layers.VehicleDataBdv.name ||
          features[this.index].layer.id === Layers.BingAccidents.name ||
          features[this.index].layer.id === Layers.BingBdv.name ||
          features[this.index].layer.id === Layers.BingConstructionSites.name ||
          features[this.index].layer.id === Layers.VehicleDataAccidents.name
        ) {
          this.features = features
          this.setDetails()
        }
      })
  }

  toggleFeature(index: number) {
    this.index = index
    this.setDetails()
  }

  setDetails() {
    this.globalId = this.features[this.index].id?.toString() ?? ''
    this.rawFeature = JSON.stringify(this.features[this.index], undefined, 2)
    const properties: RawDataModel = this.features[this.index].properties as RawDataModel
    this.zoomLevel = this.appState.getState().zoom
    this.mapObstaclesRawToDetails(properties)
    this.appState.setState(AppPaths.Obstacle, {
      selectedFeatureId: properties.id,
    })
  }

  mapObstaclesRawToDetails(properties: RawDataModel) {
    this.details?.set('RawMetric', {
      title: 'Hazards',
      items: [
        {
          description: 'ID',
          value: properties.id?.toString() ?? '',
        },
        {
          description: 'Type',
          value: properties.hazardType!,
        },
        {
          description: `Valid time (${getTimeZoneLabel(new Date(properties.startTimestamp))})`,
          value: `${
            properties.startTimestamp
              ? format(new Date(properties.startTimestamp), 'yyyy-MM-dd HH:mm')
              : '-'
          } - ${
            properties.endTimestamp
              ? format(new Date(properties.endTimestamp), 'yyyy-MM-dd HH:mm')
              : '-'
          }`,
        },
        {
          description: 'isCancelled',
          value: properties.isCancelled?.toString() ?? 'n/a',
        },
      ],
    })
  }

  close() {
    this.details.clear()
    this.appState.setState(AppPaths.Obstacle, {
      selectedFeatureId: undefined,
    })
  }
}
