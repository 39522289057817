<app-layer-category description="Provider 2">
  <app-layer-control-item
    description="Accidents"
    [isLayerVisibile]="isAccidentsLayerVisible"
    [layerId]="layerIds.Provider2Accidents.name"
    icon="car-crash"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
  <app-layer-control-item
    description="BDV"
    [isLayerVisibile]="isBdvVisible"
    [layerId]="layerIds.Provider2Bdv.name"
    icon="car-mechanic"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
  <app-layer-control-item
    description="CS"
    [isLayerVisibile]="isConstructionSiteVisible"
    [layerId]="layerIds.Provider2ConstructionSites.name"
    icon="construction"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
</app-layer-category>
