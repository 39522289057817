<rb-form-date-input
  name="dateTime"
  [label]="'Timestamp (' + timezone + ')'"
  [ngModel]="datetime"
  (ngModelChange)="onChange($event)"
  [options]="{
    enableTime: true,
    allowInput: true,
    minuteIncrement: 15,
    minDate: minDate,
    disableMobile: true,
  }"
  required
  [disabled]="disabled"
>
</rb-form-date-input>
