<div class="position-relative" (clickOutside)="hideContent()">
  <a
    type="button"
    class="d-flex justify-content-center p-2 pt-0 pe-0 text-white position-relative"
    (click)="toggleContent()"
    data-cy="account-button"
  >
    <span class="rb-ic rb-ic-my-brand-frame fs-5xl hover-effect"></span>
  </a>

  <div
    *ngIf="isContentVisible"
    class="z-1 bg-dark text-gray rounded-2 position-absolute end-0 border border-darkgray"
  >
    <div class="">
      <div class="m-2 d-flex align-items-center bg-darkgray gap-2 rounded-2 p-2">
        <span class="rb-ic rb-ic-my-brand-frame fs-3xl"></span>
        <span class="fs-xs text-nowrap" data-cy="account-name">{{ account?.username }}</span>
      </div>

      <div class="d-flex flex-column border-bottom border-darkgray pb-2">
        <div
          class="d-flex gap-2 align-items-center w-100 p-2 ho-menu-item"
          (click)="logout()"
          data-cy="account-logout"
        >
          <span class="rb-ic rb-ic-logout"></span>
          <span class="">Logout</span>
        </div>
      </div>
      <div
        class="fs-2xs p-4 rounded-bottom rounded-2 d-flex flex-column align-items-center flex-nowrap gap-1"
      >
        <span class="text-nowrap">© Robert Bosch GmbH 2024, all rights reserved</span>
        <div class="d-flex gap-2">
          <a class="text-nowrap" href="assets/corporate-information.html">Corporate information</a>
          <a class="text-nowrap" href="assets/legal-notice.html">Legal notice</a>
        </div>
        <a class="text-nowrap" href="assets/data-protection-notice.html">Data protection notice</a>
      </div>
    </div>
  </div>
</div>
