import { Component, Input } from '@angular/core'
import { DetailsModel } from 'src/app/shared/models/details.model'

@Component({
  selector: 'app-details-meta-category',
  templateUrl: './details-meta-category.component.html',
  standalone: false,
})
export class DetailsMetaCategoryComponent {
  @Input() category: DetailsModel | undefined = undefined
  @Input() icon: string = ''
  @Input() bearing: string = '0'
}
