import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-customer-layer-config',
  templateUrl: './customer-layer-config.component.html',
  standalone: false,
})
export class CustomerLayerConfigComponent {
  @Output() closed = new EventEmitter<void>()
  @Input() isLayerSelectionVisible: boolean = false

  constructor() {}

  toggleLayerSwitchVisibility() {
    this.isLayerSelectionVisible = !this.isLayerSelectionVisible
  }

  public close() {
    this.isLayerSelectionVisible = false
  }
}
