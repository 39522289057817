export enum WarningTypes {
  LowVisibility = 'LV',
  BrokendownVehicle = 'BDV',
  VehicleAccident = 'VA',
  TractionLoss = 'TL',
  ConstructionSite = 'CS',
  HeavyRain = 'HR',
  HeavySnow = 'HS',
  StrongWind = 'SW',
}

export enum WarningDatasources {
  Cariad = 'warnings.cariad',
  Daimlertruck = 'warnings.daimlertruck',
  Rcs = 'warnings.rcs',
  Bmw = 'warnings.bmw',
}

export enum TestWarningTypes {
  area = 'area',
  roadSegment = 'roadSegment',
}
