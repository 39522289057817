<app-layer-button
  class="position-absolute bottom-7 start-5 z-2"
  (visibilityToggled)="toggleLayerSwitchVisibility()"
>
</app-layer-button>

<div class="position-absolute bottom-7 start-5 z-2 pt-2" [class.d-none]="!isLayerSelectionVisible">
  <app-layer-config (closed)="close()">
    <div class="d-flex flex-column gap-3 categories">
      <app-obstacle-segment-data> </app-obstacle-segment-data>
      <app-obstacle-provider1-data
        [providerInfo$]="this.providerInfo$"
      ></app-obstacle-provider1-data>
      <app-obstacle-provider2-data
        [providerInfo$]="this.providerInfo$"
      ></app-obstacle-provider2-data>
      <app-obstacle-provider3-data
        [providerInfo$]="this.providerInfo$"
      ></app-obstacle-provider3-data>
      <app-obstacle-vehicle-data [providerInfo$]="this.providerInfo$"></app-obstacle-vehicle-data>
    </div>
  </app-layer-config>
</div>
