import { Component, Inject } from '@angular/core'
import { Subject, debounceTime, takeUntil, throttleTime } from 'rxjs'
import { LocationService } from 'src/app/core/services/location.service'
import { MapService } from 'src/app/core/services/map.service'
import { Marker } from 'maplibre-gl'
import { AppConfigModel } from 'src/app/core/models/app-config.model'
import { APP_CONFIG } from 'src/app/app.config'

@Component({
  selector: 'app-map-navigation',
  templateUrl: './map-navigation-base.component.html',
  standalone: false,
})
export class MapNavigationComponent {
  public rotateIcon: number = 0
  public isLocationRefreshEnabled = false
  public isSatelliteEnabled = false
  private cleanUp$ = new Subject<void>()
  private marker: Marker

  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private mapService: MapService,
    private locationService: LocationService,
  ) {
    const el = document.createElement('div')
    el.className = 'position-circle-marker'
    el.id = 'marker1'
    this.marker = new Marker(el)

    this.mapService.map.on('rotate', () => {
      this.rotateIcon = this.mapService.map.getBearing()
    })
  }

  toggleAutoRefresh = async () => {
    this.isLocationRefreshEnabled = !this.isLocationRefreshEnabled

    if (this.isLocationRefreshEnabled) {
      this.locationService.initialize()
      this.locationService.configure()

      this.locationService.positionChanged$
        .pipe(throttleTime(5000, undefined, { leading: true, trailing: true }))
        .pipe(takeUntil(this.cleanUp$))
        .subscribe((position) => {
          if (this.isLocationRefreshEnabled) {
            this.marker.remove()
            this.mapService.map.easeTo({
              center: position.exact,
              bearing: this.locationService.getHeading() || 0,
            })
            this.marker.setLngLat(position.exact).addTo(this.mapService.map)
          }
        })
    } else {
      this.isLocationRefreshEnabled = false
      this.locationService.stopWatchingPosition()
      this.marker.remove()
      this.cleanUp$.next()
    }
  }

  ngOnDestroy(): void {
    this.locationService.stopWatchingPosition()
    this.marker.remove()
  }

  rotateNorth() {
    this.mapService.map.resetNorthPitch()
  }

  changeMapStyle() {
    this.isSatelliteEnabled = !this.isSatelliteEnabled
    this.mapService.changeMapStyle(
      this.isSatelliteEnabled
        ? `${this.config.map_style_urls.satellite}`
        : `${this.config.map_style_urls.dark}?key=${this.config.maptiler_key}`,
    )
  }
}
