import { Component, Input, OnChanges, OnInit } from '@angular/core'

@Component({
  selector: 'app-details-links',
  templateUrl: './details-links.component.html',
  standalone: false,
})
export class DetailsLinksComponent implements OnChanges {
  private lat: number | undefined = undefined
  private lon: number | undefined = undefined
  private zoom: number | undefined = undefined

  @Input() rawFeature: string | undefined = undefined
  @Input() zoomLevel: number | undefined = undefined

  ngOnChanges(): void {
    this.lat = this.rawFeature ? JSON.parse(this.rawFeature).geometry.coordinates[1] : undefined
    this.lon = this.rawFeature ? JSON.parse(this.rawFeature).geometry.coordinates[0] : undefined
    this.zoom = this.zoomLevel
  }

  public getGoogleMapsUrl() {
    return `https://www.google.com/maps/place/${this.lat}+${this.lon}/@${this.lat},${this.lon},${this.zoom}z/data=!3m1!1e3!?entry=ttu`
  }

  public getBingMapsUrl() {
    return `https://www.bing.com/maps?v=2&cp=${this.lat}%7E${this.lon}&sp=Polyline.${this.lat}_${
      this.lon
    }_${this.lat! + 0.0000001}_${this.lon! + 0.0000001}_LINE____%2300ff00__14px_Single_Solid&lvl=${
      this.zoom
    }&style=h`
  }

  public getOsmUrl() {
    return `https://www.openstreetmap.org/#map=${this.zoom}/${this.lat}/${this.lon}`
  }
}
