import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { Observable, combineLatest, firstValueFrom } from 'rxjs'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from 'src/app/core/models'
import { FeatureCollection, Point } from 'geojson'
import { XyzTileModel } from '../models/xyz-tile.model'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { WarningDataModel } from '../models/warning-event.model'
import {
  AreaTestWarningDataModel,
  TestWarningDataModel,
} from '../models/test-warning-creation.model'
import { WarningDatasources, WarningTypes } from '../enums/warning-types.enum'
import { Feature } from '@turf/turf'
@Injectable()
export class DataSimulationWarningService {
  private warningEventsData: FeatureCollection<any, WarningDataModel>[] = [
    // Points
    {
      type: 'FeatureCollection',
      features: [],
    },
    // Lines
    {
      type: 'FeatureCollection',
      features: [],
    },

    // Polygons
    {
      type: 'FeatureCollection',
      features: [],
    },
  ]
  public isDataInitialized = false

  constructor(
    private readonly http: HttpClient,
    private appState: AppStateService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {}

  async loadWarningEvents(
    warningTypes: WarningTypes[],
    currentDatasource: WarningDatasources,
    xyzTiles: XyzTileModel[],
  ) {
    if (this.appState.isLoading) return
    this.isDataInitialized = true
    this.warningEventsData = [
      {
        type: 'FeatureCollection',
        features: [],
      },
      {
        type: 'FeatureCollection',
        features: [],
      },
      {
        type: 'FeatureCollection',
        features: [],
      },
    ]

    if (xyzTiles) {
      const warningEventsRequests$ = xyzTiles.map(async (tile) => {
        let warnings: FeatureCollection<any, WarningDataModel> | undefined = undefined
        warnings = await this.loadWarningEventsData(tile, warningTypes, currentDatasource)
        return warnings
      })
      const warningEvents$ = combineLatest(warningEventsRequests$)
      const warningEvents = await firstValueFrom(warningEvents$)

      this.warningEventsData[0].features = []
      this.warningEventsData[1].features = []
      ;(this.warningEventsData[2].features = []),
        warningEvents.map((warnings) => {
          if (warnings) {
            this.warningEventsData[1].features = this.warningEventsData[1].features.concat(
              warnings.features.filter((feature) => {
                feature.id = Number(feature.id)
                return (
                  feature.geometry.type == 'LineString' && feature.properties.isCancelled == false
                )
              }),
            )
            this.warningEventsData[2].features = this.warningEventsData[2].features.concat(
              warnings.features.filter((feature) => {
                feature.id = Number(feature.id)
                return feature.geometry.type == 'Polygon' && feature.properties.isCancelled == false
              }),
            )
          }
        })
      for (let feature of this.warningEventsData[1].features.concat(
        this.warningEventsData[2].features,
      )) {
        ;(feature.properties.featureId = Number(feature.id)),
          this.warningEventsData[0].features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: feature.properties.eventCoordinates as any,
            },
            id: Number(feature.id),
            properties: {
              name: feature.properties.name,
              expiryTime: feature.properties.expiryTime,
            },
          } as Feature<any, WarningDataModel>)
      }
    }
    return this
  }

  async loadWarningEventsData(
    xyzTiles: XyzTileModel,
    warningTypes: WarningTypes[],
    currentDatasource: WarningDatasources,
  ): Promise<FeatureCollection<any, WarningDataModel>> {
    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'json',
      }),
    }
    const warningType = warningTypes.join('&warningTypes=')
    const url = `${this.config.backend_url}rcs/simulation/warnings/${xyzTiles.z}/${xyzTiles.x}/${xyzTiles.y}?warningTypes=${warningType}&warningDatasource=${currentDatasource}`

    const result = await firstValueFrom(
      this.http.get<FeatureCollection<Point, WarningDataModel>>(url.toString(), httpOptions),
    )
    return result
  }

  getRawTestWarningEvents(): FeatureCollection<any, WarningDataModel>[] {
    return this.warningEventsData
  }

  createTestWarnings(newTestWarning: TestWarningDataModel): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'json',
        observe: 'response',
      }),
    }
    const url = `${this.config.backend_url}rcs/roadsegment-warnings`
    return this.http.put(url.toString(), newTestWarning, httpOptions)
  }

  createAreaTestWarnings(newTestWarning: AreaTestWarningDataModel): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'json',
        observe: 'response',
      }),
    }
    const url = `${this.config.backend_url}rcs/area-warnings`
    return this.http.put(url.toString(), newTestWarning, httpOptions)
  }
}
