import { Component } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { LngLat, Map } from 'maplibre-gl'
import { MapService } from 'src/app/core/services/map.service'

@Component({
  selector: 'app-scale-control',
  templateUrl: './scale-control.component.html',
  standalone: false,
})
export class ScaleControlComponent {
  public distance: string = ''
  public width: string = '100px'

  constructor(private mapService: MapService) {
    this.updateScale(this.mapService.map, { maxWidth: 120 })
    this.mapService.mapViewChanged.pipe(takeUntilDestroyed()).subscribe((view) => {
      this.updateScale(this.mapService.map, { maxWidth: 120 })
    })
  }

  updateScale(map: Map, options: any) {
    // A horizontal scale is imagined to be present at center of the map
    // container with maximum length (Default) as 100px.
    // Using spherical law of cosines approximation, the real distance is
    // found between the two coordinates.
    const maxWidth = (options && options.maxWidth) || 100

    const y = map._container.clientHeight / 2
    const maxMeters = this.getDistance(map.unproject([0, y]), map.unproject([maxWidth, y]))
    // The real distance corresponding to 100px scale length is rounded off to
    // near pretty number and the scale length for the same is found out.
    // Default unit of the scale is based on User's locale.
    if (options && options.unit === 'imperial') {
      const maxFeet = 3.2808 * maxMeters
      if (maxFeet > 5280) {
        const maxMiles = maxFeet / 5280
        this.setScale(maxWidth, maxMiles, 'mi')
      } else {
        this.setScale(maxWidth, maxFeet, 'ft')
      }
    } else if (options && options.unit === 'nautical') {
      const maxNauticals = maxMeters / 1852
      this.setScale(maxWidth, maxNauticals, 'nm')
    } else {
      this.setScale(maxWidth, maxMeters, 'm')
    }
  }

  setScale(maxWidth: number, maxDistance: number, unit: string) {
    let distance = this.getRoundNum(maxDistance)
    const ratio = distance / maxDistance

    if (unit === 'm' && distance >= 1000) {
      distance = distance / 1000
      unit = 'km'
    }
    this.distance = distance + unit
    this.width = `${maxWidth * ratio}px`
  }

  getDistance(latlng1: LngLat, latlng2: LngLat) {
    // Uses spherical law of cosines approximation.
    const R = 6371000

    const rad = Math.PI / 180,
      lat1 = latlng1.lat * rad,
      lat2 = latlng2.lat * rad,
      a =
        Math.sin(lat1) * Math.sin(lat2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.cos((latlng2.lng - latlng1.lng) * rad)

    const maxMeters = R * Math.acos(Math.min(a, 1))
    return maxMeters
  }

  getRoundNum(num: number) {
    const pow10 = Math.pow(10, `${Math.floor(num)}`.length - 1)
    let d = num / pow10

    d = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1

    return pow10 * d
  }
}
