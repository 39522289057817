import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { ObstaclePageComponent } from './obstacle-page/obstacle-page.component'
import { ObstacleVehicleDataComponent } from './components/obstacle-layer-categories/obstacle-vehicle-data/obstacle-vehicle-data.component'
import { ObstacleSegmentComponent } from './components/obstacle-layer-categories/obstacle-segment-data/obstacle-segment-data.component'
import { ObstacleLayerLegendComponent } from './components/obstacle-layer-legend/obstacle-layer-legend.component'
import { ObstacleRawDetailsComponent } from './components/obstacle-raw-details/obstacle-raw-details.component'
import { ObstacleLayerConfigComponent } from './components/obstacle-layer-config/obstacle-layer-config.component'
import { SharedModule } from '../shared/shared.module'
import { ObstacleProvider1DataComponent } from './components/obstacle-layer-categories/obstacle-provider1-data/obstacle-provider1-data.component'
import { ObstacleProvider2DataComponent } from './components/obstacle-layer-categories/obstacle-provider2-data/obstacle-provider2-data.component'
import { ObstcaleHistoryControlComponent } from './components/obstacle-history-control/obstacle-history-control.component'
import { DetailsAccordionComponent } from '../shared/components/details/details-accordion/details-accordion'
import { ObstacleRoadDetailsComponent } from './components/obstacle-road-details/obstacle-road-details.component'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'

@NgModule({
  declarations: [
   
    ObstaclePageComponent,
    ObstacleRawDetailsComponent,
    ObstacleRoadDetailsComponent,
    ObstacleVehicleDataComponent,
    ObstacleSegmentComponent,
    ObstacleLayerLegendComponent,
    ObstacleLayerConfigComponent,
    ObstacleProvider1DataComponent,
    ObstacleProvider2DataComponent,
    ObstcaleHistoryControlComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    SharedModule,
    DetailsAccordionComponent,
    NgbTooltipModule,
  ],
})
export class ObstacleModule {}
