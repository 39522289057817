import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DetailsModel } from '../../../shared/models/details.model'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { debounceTime } from 'rxjs/operators'
import { RoadSegmentPropertiesModel } from 'src/app/road-condition/model/road-segment-properties.model'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { MapGeoJSONFeature } from 'maplibre-gl'
import { Warning } from 'src/app/shared/models/road-segment.model'
import { BwcMetrics, getMetricFromMap } from '../../enums/bwc-metrics.enum'
import { Layers } from 'src/app/shared/layers-config/layers'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { format } from 'date-fns'
import { AppPaths } from 'src/app/app-routing.module'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { getTimeZoneLabel } from 'src/app/shared/utils/date.utils'

@Component({
  selector: 'app-bwc-details',
  templateUrl: './bwc-details.component.html',
  standalone: false,
})
export class BwcDetailsComponent {
  @Input() isVisible: boolean = false
  @Output() setVisible = new EventEmitter<{ details: boolean }>()
  public metric: string[] | undefined = []
  public BwcMetrics = BwcMetrics
  public Permissions = Permissions
  public details = new Map<string, DetailsModel>()
  public rawFeature: string | undefined
  public features: MapGeoJSONFeature[] = []
  public index: number = 0
  public icon = 'rb-ic rb-ic-arrow-down-frame fs-2xl'
  public globalId = ''

  constructor(
    private readonly mapEventService: MapEventsService,
    private appState: AppStateService,
  ) {
    this.details = new Map<string, DetailsModel>()

    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((features: MapGeoJSONFeature[]) => {
        this.details.clear()
        this.features.length != features.length ? (this.index = 0) : this.index
        if (features[this.index].layer.id === Layers.RoadSegment.name) {
          this.setVisible.emit({ details: true })
          this.features = features
          this.metric = this.appState.selectMetrics(Layers.RoadSegment.name)
          this.setDetails()
        }
      })
  }

  toggleFeature(index: number) {
    this.index = index
    this.setDetails()
  }

  setDetails() {
    const properties: RoadSegmentPropertiesModel = this.features[this.index]
      .properties as RoadSegmentPropertiesModel
    this.globalId = this.features[this.index].id?.toString() ?? ''
    const warnings: any = (this.features[this.index] as any).state
    const heavyRain = warnings[getMetricFromMap(BwcMetrics.RoadSegment_HeavyRain).name] as Warning
    const heavySnow = warnings[getMetricFromMap(BwcMetrics.RoadSegment_HeavySnow).name] as Warning
    const strongWind = warnings[getMetricFromMap(BwcMetrics.RoadSegment_StrongWind).name] as Warning
    const lowVisibility = warnings[
      getMetricFromMap(BwcMetrics.RoadSegment_LowVisibility).name
    ] as Warning
    const timestamp = warnings.timestamp
    this.mapMetaToDetails(properties.highway, timestamp)
    this.mapRoadConditionToDetails(heavyRain, heavySnow, strongWind, lowVisibility)
    this.appState.setState(AppPaths.BadWeatherCondition, {
      selectedFeatureId: this.globalId,
    })
  }

  private mapMetaToDetails(highway: string, timestamp: number) {
    this.details.set('Meta', {
      title: 'Road Segment',
      items: [
        {
          description: 'Type',
          value: highway,
        },
        {
          description: 'Segment ID',
          value: this.globalId,
        },
        {
          description: `Last Updated (${getTimeZoneLabel(new Date(timestamp * 1000))})`,
          value: timestamp ? format(new Date(timestamp * 1000), 'yyyy-MM-dd HH:mm:ss') : '-',
        },
      ],
    })
  }

  private mapRoadConditionToDetails(
    heavyRain: Warning,
    heavySnow: Warning,
    strongWind: Warning,
    lowVisibility: Warning,
  ) {
    if (heavyRain) {
      this.details.set(BwcMetrics.RoadSegment_HeavyRain, {
        title: 'Heavy Rain',
        items: [
          {
            description: 'Probability',
            value: (heavyRain.probability * 100).toFixed(1),
            unit: '%',
          },
          {
            description: 'Uncertainty',
            value: heavyRain.uncertainty.toString(),
            unit: 'mm/h',
          },
          {
            description: 'Value',
            value: heavyRain.value.toString(),
            unit: 'mm/h',
          },
        ],
      })
    }

    if (heavySnow) {
      this.details.set(BwcMetrics.RoadSegment_HeavySnow, {
        title: 'Heavy Snow',
        items: [
          {
            description: 'Probability',
            value: (heavySnow.probability * 100).toFixed(1),
            unit: '%',
          },
          {
            description: 'Uncertainty',
            value: heavySnow.uncertainty.toString(),
            unit: 'mm/h',
          },
          {
            description: 'Value',
            value: heavySnow.value.toString(),
            unit: 'mm/h',
          },
        ],
      })
    }

    if (strongWind) {
      this.details.set(BwcMetrics.RoadSegment_StrongWind, {
        title: 'Strong Wind',
        items: [
          {
            description: 'Probability',
            value: (strongWind.probability * 100).toFixed(1),
            unit: '%',
          },
          {
            description: 'Uncertainty',
            value: strongWind.uncertainty.toString(),
            unit: 'm/s',
          },
          {
            description: 'Value',
            value: strongWind.value.toString(),
            unit: 'm/s',
          },
        ],
      })
    }

    if (lowVisibility) {
      this.details.set(BwcMetrics.RoadSegment_LowVisibility, {
        title: 'Low Visibility',
        items: [
          {
            description: 'Probability',
            value: (lowVisibility.probability * 100).toFixed(1),
            unit: '%',
          },
          {
            description: 'Uncertainty',
            value: lowVisibility.uncertainty.toString(),
            unit: 'm',
          },
          {
            description: 'Value',
            value: lowVisibility.value.toString(),
            unit: 'm',
          },
        ],
      })
    }
  }

  close() {
    this.details.clear()
    this.appState.setState(AppPaths.BadWeatherCondition, {
      selectedFeatureId: undefined,
    })
  }
}
