import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core'
import { MapService } from '../../services/map.service'
import { takeUntil, Subject } from 'rxjs'
import { LngLatBounds } from 'maplibre-gl'
import { DebugService } from '../../services/debug.service'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from '../../models'
import { LocationService } from '../../services/location.service'
import { AuthService } from '../../services/auth.service'
import { Permissions } from '../../enums/permissions.enum'

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
  standalone: false,
})
export class DebugComponent implements OnInit, OnDestroy {
  private cleanUp = new Subject<void>()
  public isVisible: boolean = false
  public bounds: LngLatBounds | undefined
  public zoom: number | undefined
  public messages: string[] = []
  public numberOfLocationChanges = 0
  public userPermissions: Permissions[] = []
  public accessToken: string | undefined

  @HostListener('document:keydown.shift.m')
  toggleDebugDialog(): void {
    this.debugService.toggleDebugVisibility()
  }

  constructor(
    private readonly mapService: MapService,
    private readonly debugService: DebugService,
    private readonly locationService: LocationService,
    private readonly authService: AuthService,
    @Inject(APP_CONFIG) public readonly config: AppConfigModel,
  ) {}

  ngOnInit(): void {
    if (this.mapService.map && this.mapService.map.loaded()) {
      this.bounds = this.mapService.map.getBounds()
      this.zoom = this.mapService.map.getZoom()
    }

    this.mapService.mapViewChanged.pipe(takeUntil(this.cleanUp)).subscribe(() => {
      this.bounds = this.mapService.map.getBounds()
      this.zoom = this.mapService.map.getZoom()
    })

    this.debugService.messagesChanged.pipe(takeUntil(this.cleanUp)).subscribe((messages) => {
      this.messages = messages
    })

    this.debugService.debugVisibiltyChanged
      .pipe(takeUntil(this.cleanUp))
      .subscribe(async (visible) => {
        this.isVisible = visible
        if (this.isVisible) {
          this.accessToken = await this.authService.getAccessToken()
        }
      })

    this.locationService.positionChanged$.pipe(takeUntil(this.cleanUp)).subscribe(() => {
      this.numberOfLocationChanges = this.numberOfLocationChanges + 1
    })

    this.userPermissions = this.authService.getUserClaims().userPermissions
  }

  ngOnDestroy(): void {
    this.cleanUp.next()
    this.cleanUp.complete()
  }

  copy(event: Event): void {
    try {
      const target = event.currentTarget as HTMLElement
      const input = target.getElementsByTagName('input')[0]
      input.select()
      document.execCommand('copy')
    } finally {
    }
  }
}
