<app-layer-category description="Provider 1">
  <app-layer-control-item
    description="Accidents"
    [isLayerVisibile]="isAccidentsLayerVisible"
    [layerId]="layerIds.BingAccidents.name"
    icon="car-crash"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
  <app-layer-control-item
    description="BDV"
    [isLayerVisibile]="isBdvVisible"
    [layerId]="layerIds.BingBdv.name"
    icon="car-mechanic"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
  <app-layer-control-item
  description="CS"
  [isLayerVisibile]="isConstructionSiteVisible"
  [layerId]="layerIds.BingConstructionSites.name"
  icon="construction"
  (visibilityToggled)="toggleLayerVisbility($event)"
></app-layer-control-item>
</app-layer-category>
