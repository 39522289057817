import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular'
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser'
import { AppConfigModel } from '../models'

export const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1 // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message)
}

export function MSALInstanceFactory(config: AppConfigModel): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.client_id,
      authority: `https://${config.authority_base}/${config.tenant}/${config.aadb2c_policy}`,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      knownAuthorities: [config.authority_base],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      // allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback: loggerCallback,
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  })
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,

    loginFailedRoute: '/login-failed',
  }
}

export function MSALInterceptorConfigFactory(config: AppConfigModel): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>()
  protectedResourceMap.set(`${config.backend_url}*`, [config.scope])

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  }
}
