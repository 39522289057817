import { Component, ElementRef, Inject, ViewChild } from '@angular/core'
import maplibregl, { AttributionControl, Map } from 'maplibre-gl'
import { MapService } from '../../services/map.service'
import { APP_CONFIG } from 'src/app/app.config'
import { AppConfigModel } from '../../models'
import { map } from 'lodash'

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  standalone: false,
})
export class MapComponent {
  map: Map | undefined

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>

  constructor(
    private mapService: MapService,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    const initialState = { lng: 9.18667163207813, lat: 47.42111578108771, zoom: 5 }

    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style: `${this.config.map_style_urls.dark}?key=${this.config.maptiler_key}`,
      center: [initialState.lng, initialState.lat],
      zoom: initialState.zoom,
      fadeDuration: 0,
      attributionControl: false,
    })
    this.mapService.initMap(this.map)
    this.map.addControl(
      new AttributionControl({
        compact: false,
      }),
      'bottom-left',
    )
  }

  ngOnDestroy() {
    this.map?.remove()
  }
}
