<div class="d-flex flex-column gap-2">
  <div class="d-flex gap-2 align-items-center">
    <span isDemoEnabled *ngIf="providerInfo$ | async; let providers">
      {{ providers.provider4 }}
    </span>
  </div>
  <div class="d-flex flex-wrap column-gap-4 mb-2 justify-content-start">
    <app-layer-control-item
      description="Accidents"
      [isLayerVisibile]="isAccidentsLayerVisible"
      [layerId]="layerIds.VehicleDataAccidents.name"
      icon="car-crash"
      (visibilityToggled)="toggleLayerVisbility($event)"
    ></app-layer-control-item>
    <app-layer-control-item
      description="BDV"
      [isLayerVisibile]="isBdvVisible"
      [layerId]="layerIds.VehicleDataBdv.name"
      icon="car-mechanic"
      (visibilityToggled)="toggleLayerVisbility($event)"
    ></app-layer-control-item>
  </div>
</div>
