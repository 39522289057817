import { Component, Inject } from '@angular/core'
import { MapService } from 'src/app/core/services/map.service'
import { Subject, debounceTime, interval, takeUntil } from 'rxjs'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { AppPaths } from 'src/app/app-routing.module'
import { Layers } from 'src/app/shared/layers-config/layers'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { getMetricFromMap } from '../enums/customer-metrics.enum'
import { LayerCustomerWarningService } from 'src/app/shared/layers/layer-customer-warning.service'
import { DataCustomerWarningService } from 'src/app/shared/services/data-customer-warning.service'
import { AppConfigModel } from 'src/app/core/models'
import { APP_CONFIG } from 'src/app/app.config'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { AuthService } from 'src/app/core/services/auth.service'
import { WarningDatasources } from 'src/app/shared/enums/warning-types.enum'
import { customerWarningLayerProvider } from 'src/app/shared/providers/warning-layers-provider'

@Component({
  selector: 'app-customer-page',
  templateUrl: './customer-page.component.html',
  standalone: false,

  providers: [
    LayerCustomerWarningService,
    DataCustomerWarningService,
    { provide: 'metrics', useValue: getMetricFromMap },
    customerWarningLayerProvider,
  ],
})
export class CustomerPageComponent {
  private stopTimer = new Subject<void>()

  public isInLiveMode = false
  public autoRefreshIntervalInMs = 0
  public layerIds = Layers
  public currentTimestamp: Date | undefined
  public currentTimespan: number | undefined
  public warningId = ''
  public Permissions = Permissions
  public onDestroy: boolean = false
  public allowedFilters: WarningDatasources[] = []
  public currentFilter: string = ''

  constructor(
    @Inject(APP_CONFIG) public readonly config: AppConfigModel,
    private mapService: MapService,
    public appState: AppStateService,
    public AuthService: AuthService,
    @Inject('customerWarningLayer')
    public customerWarningLayerService: LayerCustomerWarningService,
  ) {
    this.allowedFilters = this.AuthService.getUserClaims().userDataFilters
    this.currentFilter = this.allowedFilters[0]
    this.mapService.activateGeoLocationSearch()
    this.mapService.setPosition(
      this.appState.getState().lat,
      this.appState.getState().lon,
      this.appState.getState().zoom,
    )

    this.updateQueryParameters()
    this.mapService.mapViewChanged
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.updateQueryParameters()
      })

    this.evaluateQueryParameters()
    this.appState.stateChanged.pipe(takeUntilDestroyed()).subscribe(() => {})
  }

  ngOnDestroy() {
    this.mapService.deactivateGeoLocationSearch()
    this.stopTimer.next()
    this.stopTimer.complete()
  }

  setLiveModeRefreshInterval(seconds: number) {
    this.autoRefreshIntervalInMs = seconds * 1000
    this.stopLiveMode()
    if (this.autoRefreshIntervalInMs > 0) {
      this.startLiveMode()
    }
  }

  startLiveMode() {
    this.isInLiveMode = true
    interval(this.autoRefreshIntervalInMs)
      .pipe(takeUntil(this.stopTimer))
      .subscribe(() => {
        this.customerWarningLayerService.reload()
      })
  }

  stopLiveMode() {
    this.isInLiveMode = false
    this.stopTimer.next()
  }

  setCurrentFilter(filter: WarningDatasources) {
    this.currentFilter = filter
    this.appState.hasWarningDatasourceChanged$.next(filter)
  }

  evaluateQueryParameters() {}

  updateQueryParameters() {
    this.appState.setState(AppPaths.Customer, {
      lat: this.mapService.map.getCenter().lat,
      lon: this.mapService.map.getCenter().lng,
      zoom: this.mapService.map.getZoom(),
    })
  }
}
