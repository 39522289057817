<app-layer-category description="Provider 3">
  <app-layer-control-item
    description="BDV"
    [isLayerVisibile]="isBdvVisible"
    [layerId]="layerIds.Provider3Bdv.name"
    icon="car-mechanic"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
  <app-layer-control-item
    description="CS"
    [isLayerVisibile]="isConstructionSiteVisible"
    [layerId]="layerIds.Provider3ConstructionSites.name"
    icon="construction"
    (visibilityToggled)="toggleLayerVisbility($event)"
  ></app-layer-control-item>
</app-layer-category>
