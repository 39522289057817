import { Component } from '@angular/core'
import { AppPaths } from 'src/app/app-routing.module'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { Layers } from 'src/app/shared/layers-config/layers'

@Component({
  selector: 'app-rc-vehicle-data',
  templateUrl: './rc-vehicle-data.component.html',
  standalone: false,
})
export class RcVehicleDataComponent {
  public layerIds = Layers
  public isHeavyRainLayerVisible: boolean | undefined
  public isFogLayerVisible: boolean | undefined
  public isSlipperyRoadVisible: boolean | undefined

  constructor(private appState: AppStateService) {
    const layers = this.appState.getState().layers
    this.isFogLayerVisible = layers?.includes(Layers.VehicleDataFog.name)
    this.isHeavyRainLayerVisible = layers?.includes(Layers.VehicleDataRain.name)
    this.isSlipperyRoadVisible = layers?.includes(Layers.VehicleDataSlippery.name)
  }

  toggleLayerVisbility(layerId: string) {
    switch (layerId) {
      case Layers.VehicleDataFog.name:
        this.isFogLayerVisible = !this.isFogLayerVisible
        break
      case Layers.VehicleDataRain.name:
        this.isHeavyRainLayerVisible = !this.isHeavyRainLayerVisible
        break
      case Layers.VehicleDataSlippery.name:
        this.isSlipperyRoadVisible = !this.isSlipperyRoadVisible
        break
      default:
        break
    }
    this.updateQueryParameters()
  }

  updateQueryParameters() {
    const layers = this.appState
      .selectLayers()
      .filter(
        (layer) =>
          !layer.includes(Layers.VehicleDataFog.name) &&
          !layer.includes(Layers.VehicleDataRain.name) &&
          !layer.includes(Layers.VehicleDataSlippery.name),
      )

    if (this.isFogLayerVisible) {
      layers.push(Layers.VehicleDataFog.name)
    }
    if (this.isHeavyRainLayerVisible) {
      layers.push(Layers.VehicleDataRain.name)
    }
    if (this.isSlipperyRoadVisible) {
      layers.push(Layers.VehicleDataSlippery.name)
    }
    this.appState.setState(AppPaths.RoadCondition, {
      layers: layers?.toString(),
    })
  }
}
