import { Component, Inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { debounceTime } from 'rxjs/operators'
import { MapGeoJSONFeature } from 'maplibre-gl'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import { DetailsModel } from 'src/app/shared/models/details.model'
import { format } from 'date-fns'
import { CustomerMetrics } from '../../enums/customer-metrics.enum'
import { WarningDataModel } from 'src/app/shared/models/warning-event.model'
import { AppPaths } from 'src/app/app-routing.module'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { getTimeZoneLabel } from 'src/app/shared/utils/date.utils'
import { AppConfigModel } from 'src/app/core/models/app-config.model'
import { APP_CONFIG } from 'src/app/app.config'
import { Permissions } from 'src/app/core/enums/permissions.enum'
import { getEmailLink } from '../../utils/customer-warning-details-utils'

@Component({
  selector: 'app-customer-warnings-details',
  templateUrl: './customer-warnings-details.component.html',
  standalone: false,
})
export class CustomerWarningsDetailsComponent {
  public selectedFeatureId = ''
  public CustomerMetrics = CustomerMetrics
  public details = new Map<string, DetailsModel>()
  public rawFeature: string | undefined
  public features: MapGeoJSONFeature[] = []
  public zoomLevel: number | undefined
  public index: number = 0
  public bearing = ''
  public requestStatus: string = 'btn-dangerous'
  public statusMessage: string = 'Delete warning'
  public shake = ''
  public refreshIntervalId: NodeJS.Timeout | undefined
  public mailToLink = ''
  public Permissions = Permissions

  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private readonly mapEventService: MapEventsService,
    public appState: AppStateService,
  ) {
    this.details = new Map<string, DetailsModel>()
    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((features: MapGeoJSONFeature[]) => {
        this.details.clear()
        this.features.length != features.length ? (this.index = 0) : this.index
        if (
          features[this.index].layer.id === 'customer-lines' ||
          features[this.index].layer.id === 'customer-hexagons'
        ) {
          this.features = features
          this.setDetails()
        }
      })
  }

  toggleFeature(index: number) {
    this.index = index
    this.setDetails()
  }

  setDetails() {
    if (
      this.selectedFeatureId &&
      this.selectedFeatureId == this.features[this.index].id?.toString()
    ) {
      this.selectedFeatureId = ''
      this.close()
      return
    }
    this.mailToLink = getEmailLink(this.features[this.index])
    this.selectedFeatureId = this.features[this.index].id?.toString() ?? ''
    const properties: WarningDataModel = this.features[this.index].properties as WarningDataModel
    this.rawFeature = JSON.stringify(this.features[this.index], undefined, 2)
    this.mapWarningsToDetails(properties)
    this.appState.setState(AppPaths.Customer, {
      selectedFeatureId: this.selectedFeatureId,
    })
  }

  mapWarningsToDetails(properties: WarningDataModel) {
    let rawMetric = {
      title: 'Hazards',
      items: [
        {
          description: 'Global ID',
          value: this.selectedFeatureId,
        },
        {
          description: 'Type',
          value: properties.name,
        },
        {
          description: 'Direction',
          value: properties.direction,
        },
        {
          description: `Valid time (${getTimeZoneLabel(new Date(properties['startTime']))})`,
          value: `${
            properties['startTime']
              ? format(new Date(properties['startTime']), 'yyyy-MM-dd HH:mm')
              : '-'
          } - ${
            properties['expiryTime']
              ? format(new Date(properties['expiryTime']), 'yyyy-MM-dd HH:mm')
              : '-'
          }`,
        },
      ],
    }
    properties.target
      ? rawMetric.items.push({ description: 'Target', value: properties.target })
      : null

    // Only add SegmentID when no area warnings are selected and visa versa
    this.features[this.index].layer.id === `${Layers.LVWarning}`
      ? rawMetric.items.push({
          description: 'H3 ID',
          value: properties.h3id,
        })
      : rawMetric.items.push({
          description: 'Segment ID',
          value: properties.segmentId,
        })

    this.details?.set('RawMetric', rawMetric)
  }

  close() {
    this.appState.setState(AppPaths.Customer, {
      selectedFeatureId: undefined,
    })
    this.selectedFeatureId = ''
    this.details.clear()
  }
}
