import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { AppPaths } from 'src/app/app-routing.module'
import { BehaviorSubject, Subject, throttleTime } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { Layers } from 'src/app/shared/layers-config/layers'
import { WarningDatasources } from 'src/app/shared/enums/warning-types.enum'
import { AuthService } from './auth.service'

export interface AppState {
  layers?: string
  metrics?: string
  zoom?: number
  lat?: number
  lon?: number
  timestamp?: string | null
  timespan?: number
  selectedFeatureId?: string
  historyEnabled?: boolean
  selectedDirection?: string
}

const initState: AppState = {
  lat: undefined,
  layers: undefined,
  metrics: undefined,
  lon: undefined,
  zoom: undefined,
  timestamp: undefined,
  timespan: undefined,
  selectedFeatureId: undefined,
  historyEnabled: undefined,
  selectedDirection: undefined,
}

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  public previousState: AppState = initState
  public state: AppState = initState
  public stateChanged = new Subject<AppState>()
  public isLoadingChanged$ = new BehaviorSubject(false)
  public isLoading = false
  public hasLastBackendCallChanged$ = new Subject<number>()
  public hasWarningDatasourceChanged$ = new Subject<WarningDatasources>()

  constructor(
    public authService: AuthService,
    private location: Location,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.state = params
      this.stateChanged.next(this.state)
    })

    this.location.onUrlChange(() => {
      const queryParams = window.location.search
      const urlParams = new URLSearchParams(queryParams)
      this.state = checkLayerPermissions(urlParams, authService)
      this.stateChanged.next(this.state)
    })

    this.isLoadingChanged$
      .pipe(throttleTime(1000, undefined, { leading: true, trailing: true }))
      .subscribe((isLoading) => {
        this.isLoading = isLoading
      })
  }

  setState(path: AppPaths, state: AppState) {
    this.previousState = this.state
    this.state = {
      ...this.state,
      ...state,
    }
    this.location.go(path, `${this.paramsToUrl()}`)
  }

  getState() {
    return this.state
  }

  private paramsToUrl() {
    const layers = this.state?.layers ? `layers=${this.state?.layers}` : ''
    const metrics = this.state?.metrics ? `&metrics=${this.state?.metrics}` : ''
    const zoom = this.state?.zoom ? `&zoom=${this.state?.zoom}` : ''
    const lat = this.state?.lat ? `&lat=${this.state?.lat}` : ''
    const lon = this.state?.lon ? `&lon=${this.state?.lon}` : ''
    const timestamp = this.state?.timestamp ? `&timestamp=${this.state?.timestamp}` : ''
    const timespan = this.state?.timespan != undefined ? `&timespan=${this.state?.timespan}` : ''
    const selectedFeatureId =
      this.state?.selectedFeatureId != undefined
        ? `&selectedFeatureId=${this.state?.selectedFeatureId}`
        : ''
    const historyEnabled =
      this.state?.historyEnabled != undefined ? `&historyEnabled=${this.state?.historyEnabled}` : ''
    const selectedDirection =
      this.state?.selectedDirection != undefined
        ? `&selectedDirection=${this.state?.selectedDirection}`
        : ''

    return `${layers}${zoom}${lat}${lon}${metrics}${timestamp}${timespan}${selectedFeatureId}${historyEnabled}${selectedDirection}`
  }

  public selectMetrics(filter?: string, includes: boolean = true) {
    const metrics = this.state.metrics?.split(',')
    if (metrics && filter && includes) {
      return metrics.filter((metric) => metric.startsWith(filter))
    }
    if (metrics && filter && !includes) {
      return metrics.filter((metrics) => !metrics.startsWith(filter))
    }
    return metrics ? metrics : []
  }

  public selectLayers(filter?: string, includes: boolean = true) {
    const layers = this.state.layers?.split(',')
    if (layers && filter && includes) {
      return layers.filter((layers) => layers.startsWith(filter))
    }
    if (layers && filter && !includes) {
      return layers.filter((layers) => !layers.startsWith(filter))
    }
    return layers ? layers : []
  }

  public selectTimestamp(): Date | undefined {
    if (this.state.timestamp) {
      return new Date(this.state.timestamp)
    }
    return undefined
  }

  public hasMapBoundsChanged(): boolean {
    if (this.state.lat != this.previousState.lat) {
      return true
    }
    if (this.state.lon != this.previousState.lon) {
      return true
    }
    if (this.state.zoom != this.previousState.zoom) {
      return true
    }
    return false
  }

  public hasTimestampChanged(): boolean {
    if (this.state.timestamp != this.previousState.timestamp) {
      return true
    }
    return false
  }

  public hasTimespanChanged(): boolean {
    if (this.state.timespan != this.previousState.timespan) {
      return true
    }
    return false
  }

  public getSelectedFeatureId(): string | undefined {
    if (this.state.selectedFeatureId) {
      return this.state.selectedFeatureId
    }
    return undefined
  }

  public getSelectedDirection(): string | undefined {
    if (this.state.selectedDirection) {
      return this.state.selectedDirection
    }
    return undefined
  }

  public hasSelectedDirectionChanged(): boolean {
    if (this.state.selectedDirection != this.previousState.selectedDirection) {
      return true
    }
    return false
  }

  public hasSelectedFeatureIdChanged(): boolean {
    if (this.state.selectedFeatureId != this.previousState.selectedFeatureId) {
      return true
    }
    return false
  }

  public hasLayerVisibilityChanged(layer: string): boolean {
    const previousLayer = this.previousState.layers?.includes(layer)
    const currentLayer = this.state.layers?.includes(layer)
    if (previousLayer != currentLayer) {
      return true
    } else {
      return false
    }
  }

  public hasMetricVisibilityChanged(metric: string): boolean {
    const previousMetric = this.previousState.metrics?.includes(metric)
    const currentMetriv = this.state.layers?.includes(metric)
    if (previousMetric != currentMetriv) {
      return true
    } else {
      return false
    }
  }

  public reset(state: AppState): void {
    this.state = {
      ...this.state,
      ...state,
    }
  }
}

// Check which layers are allowed for the user
function checkLayerPermissions(urlParams: URLSearchParams, authService: AuthService): AppState {
  let params = Object.fromEntries(urlParams)
  if (params['layers'] == undefined) {
    return params
  } else {
    const permissions = authService.getUserClaims().userPermissions
    const layers = params['layers'].split(',').filter((layer: string) => layer.trim())
    const checkedLayers = layers.filter((layer: string) => {
      return Object.values(Layers).find((entry) => {
        if (layer === entry.name) {
          return entry.permissions.length === 0 ||
            entry.permissions.some((permission: any) => {
              return permissions.includes(permission)
            })
            ? layer
            : ''
        }
        return ''
      })
    })
    params['layers'] = checkedLayers.toString()
    return params
  }
}
