import { LegendModel } from 'src/app/shared/models/legend.model'

export const obstacleLegendVehicleData: LegendModel = {
  type: 'hazard',
  category: 'vehicle',
  items: [
    {
      description: 'Accidents',
      value: 'dangerous',
    },
    {
      description: 'BDV',
      value: 'slippery-hazard',
    },
  ],
}

export const obstacleLegendProvider1Data: LegendModel = {
  type: 'hazard',
  category: 'provider1',
  items: [
    {
      description: 'Accidents',
      value: 'dangerous',
    },
    {
      description: 'BDV',
      value: 'slippery-hazard',
    },
    {
      description: 'CS',
      value: 'noncritical',
    },
  ],
}
export const obstacleLegendProvider2Data: LegendModel = {
  type: 'hazard',
  category: 'provider2',
  items: [
    {
      description: 'Accidents',
      value: 'dangerous',
    },
    {
      description: 'BDV',
      value: 'slippery-hazard',
    },
    {
      description: 'CS',
      value: 'noncritical',
    },
  ],
}

export const obstacleLegendProvider3Data: LegendModel = {
  type: 'hazard',
  category: 'provider3',
  items: [
    {
      description: 'BDV',
      value: 'slippery-hazard',
    },
    {
      description: 'CS',
      value: 'noncritical',
    },
  ],
}

export const roadHazardLegendObstacles: LegendModel = {
  type: 'prediction',
  category: 'calculated',
  items: [
    {
      description: 'warning',
      value: 'dangerous',
    },
  ],
}
