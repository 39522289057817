import { Component } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { debounceTime } from 'rxjs/operators'
import { MapGeoJSONFeature } from 'maplibre-gl'
import { MapEventsService } from 'src/app/core/services/map-events.service'
import { Layers } from 'src/app/shared/layers-config/layers'
import { DetailsModel } from 'src/app/shared/models/details.model'
import { format, getTime } from 'date-fns'
import { ObstaclesMetrics } from '../../enums/obstacle-metrics.enum'
import { WarningDataModel } from 'src/app/shared/models/warning-event.model'
import { calculateDirections } from 'src/app/shared/utils/geo.utils'
import { AppPaths } from 'src/app/app-routing.module'
import { AppStateService } from 'src/app/core/services/app-state.service'
import { get } from 'lodash'
import { getTimeZoneLabel } from 'src/app/shared/utils/date.utils'

@Component({
  selector: 'app-obstacle-road-details',
  templateUrl: './obstacle-road-details.component.html',
})
export class ObstacleRoadDetailsComponent {
  public ObstacleMetrics = ObstaclesMetrics
  public details = new Map<string, DetailsModel>()
  public rawFeature: string | undefined
  public features: MapGeoJSONFeature[] = []
  public zoomLevel: number | undefined
  public index: number = 0
  public bearing = ''
  public icon = 'rb-ic rb-ic-arrow-down-frame fs-2xl'
  public selectedFeatureId = ''

  constructor(
    private readonly mapEventService: MapEventsService,
    public appState: AppStateService,
  ) {
    this.details = new Map<string, DetailsModel>()

    this.mapEventService.featureClick
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(50))
      .subscribe((features: MapGeoJSONFeature[]) => {
        this.details.clear()
        this.features.length != features.length ? (this.index = 0) : this.index
        this.features = features
        if (features[this.index].layer.id === 'obstacle-lines') {
          this.bearing = calculateDirections(features[this.index]).start.bearing
          this.setDetails()
        }
      })
  }

  toggleFeature(index: number) {
    this.index = index
    this.setDetails()
  }

  setDetails() {
    this.selectedFeatureId = this.features[this.index].id?.toString() ?? ''
    this.rawFeature = JSON.stringify(this.features[this.index], undefined, 2)
    const properties: WarningDataModel = this.features[this.index].properties as WarningDataModel
    this.mapWarningsToDetails(properties)
    this.appState.setState(AppPaths.Obstacle, {
      selectedFeatureId: this.selectedFeatureId,
    })
  }

  mapWarningsToDetails(properties: WarningDataModel) {
    this.details?.set('RawMetric', {
      title: 'Hazards',
      items: [
        {
          description: 'Warning ID',
          value: this.selectedFeatureId,
        },
        {
          description: 'Segment ID',
          value: properties.segmentId,
        },
        {
          description: 'Type',
          value: properties.name,
        },
        {
          description: 'Probability',
          value: Math.round(properties.probability * 100).toString(),
          unit: '%',
        },
        {
          description: `Valid time (${getTimeZoneLabel(new Date(properties['startTime']))})`,
          value: `${
            properties['startTime']
              ? format(new Date(properties['startTime']), 'yyyy-MM-dd HH:mm')
              : '-'
          } - ${
            properties['expiryTime']
              ? format(new Date(properties['expiryTime']), 'yyyy-MM-dd HH:mm')
              : '-'
          }`,
        },
      ],
    })
  }

  close() {
    this.details.clear()
    this.appState.setState(AppPaths.Obstacle, {
      selectedFeatureId: undefined,
    })
  }
}
