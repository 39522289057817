import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-simulation-layer-config',
  templateUrl: './simulation-layer-config.component.html',
  standalone: false,
})
export class SimulationLayerConfigComponent {
  @Output() closed = new EventEmitter<void>()
  @Input() isLayerSelectionVisible: boolean = false

  constructor() {}

  toggleLayerSwitchVisibility() {
    this.isLayerSelectionVisible = !this.isLayerSelectionVisible
  }

  public close() {
    this.isLayerSelectionVisible = false
  }
}
