<div class="row rounded-2 bg-card mt-2">
  <div class="col text-center">
    <span class="fs-lg p-1 text-white">{{ category?.title }}</span>
    <i *ngIf="icon" class="{{ icon }}" [ngStyle]="{ transform: 'rotate(' + bearing + 'deg)' }"></i>
  </div>
</div>
<div class="row justify-content-around mt-2">
  <!-- Special case for condition category. TODO Resolve in the future -->
  <div *ngIf="category?.title !== 'Conditions'" class="col-12 col-sm-3 gy-1 text-center">
    <span class="fs-xl text-truncate text-lightgray"
      >{{ category?.items?.[0]?.value
      }}{{ category?.items?.[0]?.value ? category?.items?.[0]?.unit : '' }}</span
    >
  </div>
  <div class="col-6 col-sm-3 gy-2 text-center" *ngIf="category?.title === 'Conditions'">
    <div class="row text-start">
      <span class="fs-xs text-lightgray"
        >{{ category?.items?.[0]?.value
        }}{{ category?.items?.[0]?.value ? category?.items?.[0]?.unit : '' }}</span
      >
    </div>
    <div class="row text-start">
      <span class="fs-2xs">{{ category?.items?.[0]?.description }}</span>
    </div>
  </div>

  <app-details-item
    class="col-6 col-sm-3 gy-2 text-start"
    [description]="category?.items?.[1]?.description"
    [value]="category?.items?.[1]?.value"
    [unit]="category?.items?.[1]?.unit"
  ></app-details-item>
  <app-details-item
    class="col-6 col-sm-3 gy-2 text-start"
    [description]="category?.items?.[2]?.description"
    [value]="category?.items?.[2]?.value"
    [unit]="category?.items?.[2]?.unit"
  ></app-details-item>

  <app-details-item
    *ngIf="category?.items?.[3]?.description"
    class="col-6 col-sm-3 gy-2 text-start"
    [description]="category?.items?.[3]?.description"
    [value]="category?.items?.[3]?.value"
    [unit]="category?.items?.[3]?.unit"
  ></app-details-item>
</div>
